#t-admin-megamenu {
  img {
    display: inline;
    &.nav-logo {
      height: 25px;
    }
  }
  .dropdown > a::before {
    content: none;
    right: 0;
  }

}