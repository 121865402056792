#home {
  .main-container {
    background-color: $base-color;
    padding: 0;

    .btn {
      border: none;
    }
  }

  /*! events */
  .events-wrap {
    background-color: #ebebeb;
    padding: 20px 0px 35px;

    //background-color: #f1f1f1;

    h2 {
      font-weight: bold;
    }
    .events-title {
      padding: 60px 0 15px;
      h2 {
        margin: 0;
      }
    }

    .calendar-img {
      h5 {
        background: url(../../images/event-icon.png) no-repeat top center;
        color: #000;
        text-align: center;
        font-family: 'Open Sans', sans-serif;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 40px;
        padding: 24px 0;
      }
    }

    .event-item {
      p {
        text-align: center;
        color: #3C6FA9;
        text-transform: uppercase;
        font-size: 20px;
        line-height: 22px;
        font-weight: 600;
      }

      h3 {
        line-height: 17px;
        text-align: center;
        margin-bottom: 20px;
        a {
          color: #102C40;
          font-family: 'Open Sans', sans-serif;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 20px;

        }
      }
    }

    .view-more {
      padding: 5px 10px;
      border: 1px solid #f2f2f2;

      position: relative;
      font-family: 'Open Sans Condensed', sans-serif;
      text-transform: uppercase;
      font-weight: 700;
      color: #0778B2;
      float: right;
      img {
        margin-left: 20px;
      }
    }
  }
}
