.footer {
  .footer-text {
    margin-top: 25px;
    text-align: center;
    @media (min-width: 768px) {
      text-align: left;
    }
  }
  .logo-footer {
    text-align: center;
    img {
      display: inline-block;
    }
  }
  .nav.navbar-nav.t-main-navbar-nav {
    & > li {
      float: none;
      & > a {
        padding: 0;
        text-transform: uppercase;
        color: $base-color-lighter;
        font-weight: 700;
        line-height: 30px;
        font-size: 16px;
        &:hover {
          color: whitesmoke;
          background-color: transparent;
        }
      }
    }
  }
}