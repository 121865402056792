@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Righteous");
/*
Theme Name: iDea - Clean & Powerful Bootstrap Theme
Author: HtmlCoder
Author URI: http://www.htmlcoder.me
Author e-mail: htmlcoder.me@gmail.com
Version: 1.6
Created: September 2014
License URI: http://support.wrapbootstrap.com/
File Description: Blue Skin
*/
/* 1 Typography
----------------------------------------------------------------------------- */
a, .text-default {
  color: #364EA2; }

a:hover,
a:focus {
  color: #2c5fb3; }

mark,
.mark {
  background-color: #364EA2;
  color: #ffffff; }

.table > thead > tr > th {
  border-bottom: 1px solid #364EA2;
  background-color: #364EA2;
  color: #ffffff; }

.table-bordered {
  border-color: #364EA2; }

/* 2 Layout
----------------------------------------------------------------------------- */
.default-bg {
  background-color: #364EA2;
  color: #ffffff; }

/* Backgrounds
---------------------------------- */
.default-translucent-bg:after {
  background-color: rgba(54, 78, 162, 0.7); }

/* 3 Pages
----------------------------------------------------------------------------- */
.blogpost .post-info {
  background-color: #364EA2;
  color: #ffffff; }

.blogpost .side .social-links li a {
  background-color: transparent;
  border-color: transparent;
  color: #364EA2; }

.blogpost ul.links li a:hover {
  color: #364EA2; }

/* timeline layout */
.timeline-date-label {
  color: #364EA2; }

@media (min-width: 768px) {
  .timeline .timeline-item:before {
    background-color: #ffffff;
    border: 1px solid #364EA2; } }

/* 4 Template Components
----------------------------------------------------------------------------- */
/* Buttons
---------------------------------- */
/* Gray Buttons */
.btn-gray:hover,
.btn-gray:focus,
.btn-gray:active,
.btn-gray.active,
.open .dropdown-toggle.btn-gray {
  color: #ffffff;
  background-color: #364EA2;
  border-color: #364EA2; }

/* Light Gray Buttons */
.btn-light-gray:hover,
.btn-light-gray:focus,
.btn-light-gray:active,
.btn-light-gray.active,
.open .dropdown-toggle.btn-light-gray {
  color: #ffffff;
  background-color: #364EA2;
  border-color: #364EA2; }

/* Default Buttons */
.btn-default {
  background-color: #364EA2;
  color: #ffffff !important;
  border-color: #2c5fb3; }

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  color: #ffffff !important;
  background-color: #2c5fb3;
  border-color: #2c5fb3; }

.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default {
  background-color: #2c5fb3;
  border-color: #2c5fb3; }

/* Button with transparent bg
---------------------------------- */
.btn-white {
  background-color: transparent;
  color: #2c5fb3;
  border: 1px solid #364EA2; }

.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active,
.open .dropdown-toggle.btn-white {
  color: #ffffff;
  background-color: #2c5fb3;
  border-color: #2c5fb3; }

/* Button background transitions
---------------------------------- */
.hvr-sweep-to-right:before,
.hvr-sweep-to-left:before,
.hvr-sweep-to-bottom:before,
.hvr-sweep-to-top:before,
.hvr-bounce-to-right:before,
.hvr-bounce-to-left:before,
.hvr-bounce-to-bottom:before,
.hvr-bounce-to-top:before,
.hvr-radial-out:before,
.hvr-rectangle-out:before,
.hvr-shutter-out-horizontal:before,
.hvr-shutter-out-vertical:before {
  background: #2c5fb3; }

/* Forms
---------------------------------- */
.default-bg .form-control {
  border-color: #364EA2;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.35);
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.35);
  color: #ffffff; }

.default-bg .form-control:focus {
  border-color: #364EA2;
  background-color: rgba(0, 0, 0, 0.25); }

.default-bg .input-group-addon {
  background-color: #2c5fb3;
  border-color: #364EA2;
  color: #ffffff; }

.dropdown .active > a > .badge,
.dropdown a:hover .badge,
.dropdown .open > a > .badge {
  border-color: #364EA2; }

/* Pager
---------------------------------- */
ul.pagination li > a:hover {
  background-color: transparent;
  color: #ffffff;
  background-color: #364EA2;
  border-color: #364EA2; }

ul.pagination li.active a,
.pagination > .active > a:hover,
.pagination > .active > a:focus {
  color: #ffffff;
  background-color: #364EA2;
  border-color: #364EA2; }

/* Tabs
---------------------------------- */
.nav-tabs > li.active > a:after {
  background-color: #364EA2; }

.tab-content:after {
  background-color: #364EA2; }

@media (max-width: 480px) {
  .nav-tabs > li > a:hover:after {
    background-color: #364EA2; } }

/* Horizontal tabs version 2 */
.tabs-style-2 .nav-tabs {
  border-bottom: 1px solid #364EA2; }

.tabs-style-2 .nav-tabs > li.active > a,
.tabs-style-2 .nav-tabs > li.active > a:hover,
.tabs-style-2 .nav-tabs > li.active > a:focus,
.tabs-style-2 .nav-tabs > li > a:hover {
  border: 1px solid #364EA2;
  background-color: #364EA2;
  color: #ffffff; }

/* vertical tabs */
.vertical .nav-tabs > li > a:hover:after {
  background-color: #364EA2; }

/* Nav pills
---------------------------------- */
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus,
.nav-pills > li > a:hover,
.nav-pills > li > a:focus {
  border: 1px solid #f3f3f3;
  color: #364EA2;
  background-color: #fafafa; }

.nav-pills > li.active > a:after {
  color: #364EA2; }

.nav-pills > li.active > a:hover:after,
.nav-pills > li.active > a:focus:after,
.nav-pills > li > a:hover:after,
.nav-pills > li > a:focus:after {
  color: #364EA2; }

.light .footer-content .nav-pills > li.active > a:hover,
.light .footer-content .nav-pills > li > a:hover {
  color: #2c5fb3; }

.nav-pills > .active > a > .badge {
  color: #2c5fb3;
  background-color: #ffffff;
  border-color: #2c5fb3; }

/* Collapse
---------------------------------- */
.panel-heading a {
  background-color: #364EA2;
  color: #ffffff; }

.panel-heading a:hover,
.panel-transparent .panel-heading a:hover {
  background-color: #364EA2;
  color: #ffffff; }

/* Progress bars
---------------------------------- */
.progress-bar-default {
  background-color: #364EA2; }

/* Modals
---------------------------------- */
.modal-content .modal-header {
  background-color: #364EA2; }

/* Images and Images overlay
---------------------------------- */
.overlay a {
  color: #364EA2;
  background-color: #ffffff; }

.overlay-links a + a {
  color: #ffffff;
  background-color: #364EA2; }

a.overlay i {
  color: #364EA2; }

/* 5 Navigations
----------------------------------------------------------------------------- */
/* first level active menu items on hover/focus */
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus {
  color: #364EA2;
  background-color: transparent; }

.main-navigation .navbar-nav > .open > a,
.main-navigation .navbar-nav > .open > a:hover,
.main-navigation .navbar-nav > .open > a:focus {
  color: #666666;
  background-color: #fafafa; }

/* second level menu items on focus/hover and when opened */
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus,
.dropdown-menu .menu > .active > a,
.dropdown-menu .menu > .active > a:hover,
.dropdown-menu .menu > .active > a:focus {
  background-color: #ffffff;
  color: #364EA2;
  border-color: #f1f1f1; }

#offcanvas .nav .open > a,
#offcanvas .nav .open > a:hover,
#offcanvas .nav .open > a:focus,
#offcanvas .nav > li > a:hover,
#offcanvas .nav > li > a:focus,
#offcanvas .navbar-nav > li.active > a {
  color: #364EA2;
  background-color: #ffffff; }

/* mega menu menu items on focus/hover and when opened */
.mega-menu .menu > li > a:hover,
.mega-menu .menu > li > a:focus {
  background-color: #ffffff;
  color: #364EA2;
  border-color: #f1f1f1; }

/* arrows for parent menu items */
.navbar-default .navbar-nav > .active.dropdown > a:before {
  color: #364EA2; }

.navbar-default .navbar-nav > .dropdown.open > a:before {
  color: #e5e5e5; }

.dropdown .dropdown.open > a:before,
.transparent.header:not(.dark) .dropdown .dropdown.open > a:before {
  color: #364EA2; }

/* mobile menu toggle button on hover/focus */
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #fafafa;
  border-color: #364EA2; }

.navbar-default .navbar-toggle:hover .icon-bar,
.navbar-default .navbar-toggle:focus .icon-bar {
  background-color: #364EA2; }

@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #364EA2;
    background-color: transparent; }
  /* first level items hover and focus states */
  .navbar-default .navbar-nav > li > a:hover,
  .navbar-default .navbar-nav > li > a:focus,
  .navbar-default .navbar-nav > .active > a:hover,
  .navbar-default .navbar-nav > .active > a:focus {
    color: #364EA2;
    background-color: #ffffff; }
  /* second level items on hover/focus */
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #364EA2; } }

/* Subfooter navigation
---------------------------------- */
.subfooter .navbar-default .nav > li > a:hover,
.subfooter .navbar-default .nav > .active > a:hover,
.subfooter .navbar-default .nav > .active > a:focus {
  background-color: transparent;
  color: #364EA2; }

/* 6 Blocks/Widgets
----------------------------------------------------------------------------- */
/* Boxes
---------------------------------- */
.box.gray-bg:hover {
  color: #364EA2; }

.box.default-bg:hover {
  background-color: #ffffff;
  border: 1px solid #364EA2; }

.box.default-bg:hover * {
  color: #364EA2; }

.box-style-1:not(.team-member):hover i {
  color: #364EA2; }

.box-style-1.default-bg:hover i,
.box-style-1.dark-bg:hover i {
  color: inherit; }

.box-style-2:hover .default-bg {
  background-color: #ffffff;
  border: 1px solid #364EA2; }

.box-style-2:hover .default-bg * {
  color: #364EA2; }

.box-style-2:hover .white-bg * {
  color: #364EA2; }

.box-style-3:hover .default-bg {
  background-color: #ffffff;
  border: 1px solid #364EA2; }

.box-style-3:hover .default-bg * {
  color: #364EA2; }

.box-style-3:hover .white-bg * {
  color: #364EA2; }

/* Tags cloud block
---------------------------------- */
.tag a:hover {
  color: #ffffff;
  background-color: #364EA2;
  border-color: #364EA2; }

/* Social icons block
---------------------------------- */
.social-links li a {
  background: #364EA2;
  border: 1px solid #364EA2;
  color: #ffffff; }

.social-links li a:hover {
  background-color: transparent;
  border-color: #2c5fb3;
  color: #2c5fb3; }

.social-links .dropdown.open > button > i:before {
  content: "\f00d";
  color: #364EA2; }

.header-top .social-links li a:hover {
  color: #2c5fb3; }

/* search and login
---------------------------------- */
.header-top-dropdown .dropdown.open > button,
.header-top-dropdown .dropdown > button:hover {
  color: #364EA2; }

/* Pricing tables
---------------------------------- */
.plan .popover-title {
  background-color: #364EA2;
  color: #ffffff; }

.gray .plan .header h3 {
  color: #364EA2; }

.white .plan .header {
  color: #ffffff;
  background: #364EA2; }

.stripped .header {
  background: #364EA2;
  -webkit-box-shadow: 0px 0px 60px #2c5fb3 inset;
  -moz-box-shadow: 0px 0px 60px #2c5fb3 inset;
  box-shadow: 0px 0px 60px #2c5fb3 inset;
  color: #ffffff; }

/* 7 Main Slideshow
----------------------------------------------------------------------------- */
/* Slider Revolution captions */
.default_bg {
  background-color: #364EA2;
  color: #ffffff; }

/* Revolution Nav Controls */
.tparrows.round:hover {
  color: #ffffff;
  background-color: #364EA2; }

.tp-bullets.simplebullets.round .bullet:hover,
.tp-bullets.simplebullets.round .bullet.selected {
  background-color: #364EA2; }

.tp-bannertimer {
  background-color: rgba(54, 78, 162, 0.7); }

/* 8 Owl carousel
----------------------------------------------------------------------------- */
.owl-prev {
  background-color: #364EA2;
  color: #f1f1f1; }

.owl-next {
  background-color: #f1f1f1;
  color: #364EA2; }

.content-slider-with-controls .owl-nav div,
.content-slider-with-controls-autoplay .owl-nav div {
  background-color: #364EA2;
  color: #f1f1f1; }

.content-slider-with-controls .owl-prev:hover,
.content-slider-with-controls .owl-next:hover,
.content-slider-with-controls-autoplay .owl-prev:hover,
.content-slider-with-controls-autoplay .owl-next:hover {
  background-color: #f1f1f1;
  color: #364EA2; }

.owl-dots .owl-dot.active span,
.owl-dots .owl-dot:hover span {
  background: #364EA2; }

/* 1 Typography
----------------------------------------------------------------------------- */
html {
  height: 100%; }

body {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 1.5;
  color: #666666;
  background-color: #ffffff; }

body.full-height,
body.full-height .page-wrapper {
  background-color: #fafafa;
  min-height: 100%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #4A4B50;
  line-height: 1.2; }
  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a {
    color: #4A4B50; }

h1 {
  font-size: 45px;
  font-weight: 700; }

h2 {
  font-family: "Righteous", cursive;
  text-transform: uppercase;
  font-size: 33px;
  margin-bottom: 15px; }

h3 {
  font-size: 26px; }

h4 {
  font-size: 21px;
  font-weight: 700; }

h5 {
  font-size: 19px;
  text-transform: uppercase;
  font-weight: 700; }

h6 {
  font-weight: 700; }

/* Medium devices (desktops, tablets, 992px and up) */
@media (min-width: 992px) {
  .title {
    margin-top: 0; } }

.sidebar .title {
  margin-bottom: 15px;
  margin-top: 20px;
  position: relative; }

.page-header {
  margin-top: 20px;
  padding-top: 20px; }

p {
  margin-bottom: 20px; }

a {
  color: #364EA2;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }
  a:hover {
    color: #2c5fb3; }
  a:focus, a:active {
    outline: none;
    color: #2c5fb3; }

.no-trans * {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important; }

img {
  display: block;
  max-width: 100%;
  height: auto; }

blockquote {
  border-left: none;
  display: block;
  margin: 20px auto 20px;
  font-size: 16px;
  position: relative;
  padding: 10px 10px 10px 0px;
  margin-left: 40px; }

blockquote:after {
  content: "“";
  width: 25px;
  height: 25px;
  line-height: 64px;
  font-size: 64px;
  font-family: "PT Serif", serif;
  position: absolute;
  top: 12px;
  left: -45px; }

.blockquote:before {
  font-family: "PT Serif", serif;
  content: "“";
  font-size: 40px; }

blockquote.inline {
  padding: 0; }

blockquote.inline p {
  width: 60%;
  display: inline-block;
  margin: 0; }

blockquote.inline footer {
  width: 37%;
  display: inline-block;
  padding-left: 5px; }

blockquote.inline:after {
  top: 0; }

mark,
.mark {
  background-color: #364EA2;
  color: #ffffff; }

pre {
  padding: 20px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px; }

.text-muted {
  color: #999999; }

.text-default {
  color: #364EA2; }

.well {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  background-color: #f3f3f3;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05); }

.icons-page i {
  color: #333;
  font-size: 20px; }

hr.dark,
#footer .footer hr {
  border-color: #333333; }

#footer.light hr {
  border-color: #dddddd; }

/* Lists
---------------------------------- */
ul {
  list-style: square; }

.list-icons,
.list {
  list-style: none;
  padding: 0; }

.list-icons li,
.list li {
  padding: 5px 0; }

.list-icons li i {
  width: 25px;
  text-align: center; }

.list li .vertical-divider {
  padding-right: 15px;
  position: relative; }

.list li .vertical-divider:after {
  position: absolute;
  content: "";
  height: 60%;
  width: 1px;
  background-color: #CECECE;
  right: 9px;
  top: 5px; }

.list-inline {
  margin-top: 9px;
  margin-bottom: 8px; }

/* Tables
---------------------------------- */
.table:not(.table-bordered) {
  border-bottom: 2px solid #f3f3f3; }

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 1px solid #364EA2;
  background-color: #364EA2;
  color: #fff; }

.table-bordered {
  border-color: #364EA2; }

tbody {
  background-color: #ffffff; }

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 8px 15px;
  border-top: 1px solid #f3f3f3; }

.table-striped tbody {
  background-color: #fafafa; }

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #f1f1f1; }

.table-striped.table > tbody > tr > td {
  border-color: #e8e8e8; }

.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border-color: #f3f3f3; }

.dl-horizontal dd {
  margin-bottom: 10px; }

.dark .table:not(.table-bordered) {
  border-bottom: 2px solid #515151; }

.dark .table > thead > tr > th {
  border-bottom: 1px solid #27272a;
  background-color: #27272a;
  color: #cdcdcd; }

.dark tbody {
  background-color: transparent; }

.dark .table > thead > tr > th,
.dark .table > tbody > tr > th,
.dark .table > tfoot > tr > th,
.dark .table > thead > tr > td,
.dark .table > tbody > tr > td,
.dark .table > tfoot > tr > td {
  border-top: 1px solid #515151; }

.dark .table-hover > tbody > tr:hover {
  background-color: #27272a; }

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .dl-horizontal dd {
    margin-left: 150px; }
  .dl-horizontal dt {
    max-width: 120px; } }

/* 2 Layout
----------------------------------------------------------------------------- */
.page-wrapper {
  background-color: #ffffff; }

.header-top {
  background-color: #4A4B50;
  border-bottom: 1px solid #dadada;
  -webkit-box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.03) inset;
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.03) inset; }

.header-top.dark {
  background-color: #27272a;
  border-bottom: 1px solid #000000;
  color: #cdcdcd; }

.header-top.banner {
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: none; }

.header {
  padding: 0;
  border-bottom: 1px solid #364EA2;
  background-color: #ffffff; }
  .header .title-container {
    margin-top: 50px; }
    .header .title-container .title {
      font-size: 30px;
      font-family: "Righteous", cursive;
      color: #364EA2; }
    @media (min-width: 1400px) {
      .header .title-container {
        margin-top: 110px; } }

.header.dark {
  background-color: #36373C;
  border-top: 1px solid #515151;
  border-bottom-color: transparent !important; }

.header.header-small {
  padding: 10px 0; }

.banner {
  background-color: #f1f1f1;
  border-bottom: 1px solid #eaeaea; }

.page-intro {
  padding: 10px 0;
  -webkit-box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.06) inset;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.06) inset;
  background-color: #fafafa;
  border-bottom: 1px solid #dadada; }

.banner + .page-intro {
  margin-top: -56px !important;
  position: relative;
  background-color: rgba(250, 250, 250, 0.9);
  z-index: 2; }

.page-top {
  padding: 40px 0; }

.main-container {
  padding: 40px 0; }

.full-height .main-container {
  padding-top: 80px; }

.section {
  padding: 25px 0; }

.footer-top.light-gray-bg {
  border-top: 1px solid #f1f1f1; }

.footer {
  padding: 30px 0;
  background-color: #262626;
  border-top: 1px solid #3a3a3a;
  border-bottom: 1px solid #333333; }

.subfooter {
  padding: 25px 0;
  background-color: #000000; }

.block {
  padding-bottom: 40px; }

.sidebar .block:first-child {
  margin-top: 2px; }

.sidebar .block {
  padding-bottom: 0; }

@media (min-width: 768px) {
  :not(.fixed-header-on) .header.transparent {
    position: absolute;
    width: 100%;
    z-index: 30;
    background-color: rgba(255, 255, 255, 0.3);
    border-color: transparent; }
  :not(.fixed-header-on) .dark.header.transparent {
    background-color: rgba(0, 0, 0, 0.3); } }

/* Boxed Layout
---------------------------------- */
.boxed .page-wrapper {
  margin-right: auto;
  margin-left: auto;
  position: relative;
  -webkit-box-shadow: 0px 0px 10px #cacaca;
  box-shadow: 0px 0px 10px #cacaca; }

.pattern-1 {
  background-image: url("../images/pattern-1.png");
  background-repeat: repeat;
  background-position: 0 0; }

.pattern-4 .page-wrapper,
.pattern-5 .page-wrapper,
.pattern-6 .page-wrapper,
.pattern-7 .page-wrapper,
.pattern-8 .page-wrapper,
.pattern-9 .page-wrapper {
  -webkit-box-shadow: none;
  box-shadow: none; }

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .boxed .page-wrapper {
    width: 750px; } }

/* Medium devices (desktops, tablets, 992px and up) */
@media (min-width: 992px) {
  .boxed .page-wrapper {
    width: 970px; } }

/* Large devices (Large desktops 1200px and up) */
@media (min-width: 1200px) {
  .boxed .page-wrapper {
    width: 1170px; } }

/* Backgrounds
---------------------------------- */
.light .footer {
  background-color: #f7f7f7;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #d1d1d1;
  position: relative; }

.footer-top.light-gray-bg + .light .footer {
  border-top: 1px solid #f1f1f1; }

.light .subfooter {
  background-color: #e7e7e7; }

.white-bg {
  background-color: #ffffff !important;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border: none !important; }

.light-gray-bg {
  background-color: #fafafa; }
  .light-gray-bg.bordered {
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea; }

.header-top {
  font-size: 12px; }
  .header-top.white-bg {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px solid #dadada !important; }

.gray-bg {
  background-color: #f1f1f1;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.13) !important;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.13) !important;
  position: relative; }
  .gray-bg:before {
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    height: 5px;
    content: "";
    width: 100%;
    -webkit-box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.13);
    box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.13); }
  .gray-bg.header {
    border-bottom: 1px solid #dadada; }
  .gray-bg.header, .gray-bg.header:before {
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }

.dark-bg {
  background-color: #000000;
  color: #ffffff; }
  .dark-bg h1,
  .dark-bg h2,
  .dark-bg h3,
  .dark-bg h4,
  .dark-bg h5,
  .dark-bg h6 {
    color: #ffffff; }
  .dark-bg a:not(.btn) {
    color: #ffffff;
    text-decoration: underline; }
  .dark-bg a:not(.btn):hover {
    color: #ffffff;
    text-decoration: none; }

.default-bg {
  background-color: #364EA2;
  color: #ffffff; }
  .default-bg h1,
  .default-bg h2,
  .default-bg h3,
  .default-bg h4,
  .default-bg h5,
  .default-bg h6 {
    color: #ffffff; }
  .default-bg a:not(.btn) {
    color: #ffffff;
    text-decoration: underline; }
  .default-bg a:not(.btn):hover {
    color: #ffffff;
    text-decoration: none; }
  .default-bg.text-muted,
  .default-bg.text-muted h1,
  .default-bg.text-muted h2,
  .default-bg.text-muted h3,
  .default-bg.text-muted h4,
  .default-bg.text-muted h5,
  .default-bg.text-muted h6 {
    color: #f1f1f1; }

.dark-bg.text-muted,
.dark-bg.text-muted h1,
.dark-bg.text-muted h2,
.dark-bg.text-muted h3,
.dark-bg.text-muted h4,
.dark-bg.text-muted h5,
.dark-bg.text-muted h6,
.gray-bg.text-muted,
.gray-bg.text-muted h1,
.gray-bg.text-muted h2,
.gray-bg.text-muted h3,
.gray-bg.text-muted h4,
.gray-bg.text-muted h5,
.gray-bg.text-muted h6 {
  color: #999999; }

/* Parallax effect
---------------------------------- */
.no-touch .light-translucent-bg,
.no-touch .dark-translucent-bg,
.no-touch .default-translucent-bg,
.no-touch .parallax {
  background-attachment: fixed !important; }

.light-translucent-bg,
.dark-translucent-bg,
.default-translucent-bg,
.parallax {
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important; }

.parallax {
  background-position: 50% 0;
  background-repeat: no-repeat;
  z-index: 1;
  position: relative;
  padding: 50px 0; }

.light-translucent-bg,
.dark-translucent-bg,
.default-translucent-bg {
  background-position: 50% 0;
  background-repeat: no-repeat;
  z-index: 1;
  position: relative; }

.light-translucent-bg:after,
.dark-translucent-bg:after,
.default-translucent-bg:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%; }

.light-translucent-bg {
  color: #333;
  border-bottom: 1px solid #dadada;
  border-top: 1px solid #dadada; }

.header + .banner > .light-translucent-bg {
  border-top: none; }

.slider-banner-container .light-translucent-bg {
  border: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  margin: 0 !important;
  bottom: 0; }

.slider-banner-container .dark-translucent-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  margin: 0 !important;
  bottom: 0; }

.slider-banner-container li .light-translucent-bg:after,
.slider-banner-container li .dark-translucent-bg:after {
  background-color: transparent; }

.light-translucent-bg .separator-2 {
  background-color: #333; }

.light-translucent-bg:after {
  background-color: rgba(255, 255, 255, 0.7); }

.dark-translucent-bg:after {
  background-color: rgba(0, 0, 0, 0.7); }

.dark-translucent-bg,
.dark-translucent-bg h1,
.dark-translucent-bg h2,
.dark-translucent-bg h3,
.dark-translucent-bg h4,
.dark-translucent-bg h5,
.dark-translucent-bg h6 {
  color: #ffffff; }

.dark-translucent-bg a:not(.btn) {
  text-decoration: underline;
  color: #ffffff; }

.dark-translucent-bg a:not(.btn):hover {
  text-decoration: none;
  color: #ffffff; }

.default-translucent-bg:after {
  background-color: rgba(232, 76, 61, 0.7); }

.default-translucent-bg,
.default-translucent-bg h1,
.default-translucent-bg h2,
.default-translucent-bg h3,
.default-translucent-bg h4,
.default-translucent-bg h5,
.default-translucent-bg h6 {
  color: #ffffff; }

.default-translucent-bg a:not(.btn) {
  text-decoration: underline;
  color: #ffffff; }

.default-translucent-bg a:not(.btn):hover {
  text-decoration: none;
  color: #ffffff; }

.light-translucent-bg .container,
.dark-translucent-bg .container,
.default-translucent-bg .container {
  z-index: 3;
  position: relative; }

.parallax-bg {
  background: url("../images/parallax-bg.jpg") 50% 0px no-repeat; }

.parallax-bg-2 {
  background: url("../images/parallax-bg-2.jpg") 50% 0px no-repeat; }

.parallax-bg-3 {
  background: url("../images/parallax-bg-3.jpg") 50% 0px no-repeat; }

/* Custom Grid
---------------------------------- */
.grid-space-20 {
  margin-right: -10.5px;
  margin-left: -10.5px; }
  .grid-space-20 .col-lg-6,
  .grid-space-20 .col-lg-4,
  .grid-space-20 .col-lg-3,
  .grid-space-20 .col-md-6,
  .grid-space-20 .col-md-4,
  .grid-space-20 .col-md-3,
  .grid-space-20 .col-sm-6,
  .grid-space-20 .col-sm-4,
  .grid-space-20 .col-sm-3,
  .grid-space-20 .col-xs-6,
  .grid-space-20 .col-xs-4,
  .grid-space-20 .col-xs-3 {
    padding-left: 10px;
    padding-right: 10px; }

.grid-space-10 {
  margin-right: -6px;
  margin-left: -6px; }
  .grid-space-10 .col-lg-6,
  .grid-space-10 .col-lg-4,
  .grid-space-10 .col-lg-3,
  .grid-space-10 .col-md-6,
  .grid-space-10 .col-md-4,
  .grid-space-10 .col-md-3,
  .grid-space-10 .col-sm-6,
  .grid-space-10 .col-sm-4,
  .grid-space-10 .col-sm-3,
  .grid-space-10 .col-xs-6,
  .grid-space-10 .col-xs-4,
  .grid-space-10 .col-xs-3 {
    padding-left: 5px;
    padding-right: 5px; }

.grid-space-0 {
  margin-right: 0;
  margin-left: 0; }
  .grid-space-0 .col-lg-6,
  .grid-space-0 .col-lg-4,
  .grid-space-0 .col-lg-3,
  .grid-space-0 .col-md-6,
  .grid-space-0 .col-md-4,
  .grid-space-0 .col-md-3,
  .grid-space-0 .col-sm-6,
  .grid-space-0 .col-sm-4,
  .grid-space-0 .col-sm-3,
  .grid-space-0 .col-xs-6,
  .grid-space-0 .col-xs-4,
  .grid-space-0 .col-xs-3 {
    padding-left: 0;
    padding-right: 0; }

.grid-space-0 > div {
  margin-bottom: 0;
  padding-bottom: 0; }

/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) {
  .footer-content {
    margin: 40px 0; } }

/* Fixed navigation
---------------------------------- */
.fixed-header-on .header.fixed {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1006;
  opacity: 0;
  filter: alpha(opacity=0);
  border-bottom: 1px solid #f1f1f1;
  padding: 7px 0;
  background-color: rgba(255, 255, 255, 0.95); }

.fixed-header-on .dark.header.fixed {
  border-bottom: 1px solid #36373C;
  background-color: rgba(54, 55, 60, 0.95); }

.fixed-header-on.boxed .header.fixed {
  left: auto; }

.fixed-header-on .header .logo {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  margin: 2px 0 -4px 0;
  display: none; }

.fixed-header-on .header .site-slogan {
  display: none; }

.fixed-header-on .header .navbar.navbar-default {
  margin: 0; }

.fixed-header-on .header.fixed.animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s; }

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .fixed-header-on.boxed .header.fixed {
    width: 750px; } }

/* Medium devices (desktops, tablets, 992px and up) */
@media (min-width: 992px) {
  .fixed-header-on .header .logo {
    display: block; }
  .fixed-header-on.boxed .header.fixed {
    width: 970px; } }

/* Large devices (Large desktops 1200px and up) */
@media (min-width: 1200px) {
  .fixed-header-on.boxed .header.fixed {
    width: 1170px; } }

/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) {
  /* Fixed header position */
  .header.fixed {
    position: relative !important;
    top: auto !important; } }

/* Miscellaneous
---------------------------------- */
.pl-5 {
  padding-left: 5px; }

.pl-10 {
  padding-left: 10px; }

.pl-20 {
  padding-left: 20px; }

.pr-5 {
  padding-right: 5px; }

.pr-10 {
  padding-right: 10px; }

.pr-20 {
  padding-right: 20px; }

.mt-10 {
  margin-top: 10px; }

.mt-20 {
  margin-top: 20px; }

.mb-10 {
  margin-bottom: 10px; }

.mb-20 {
  margin-bottom: 20px; }

.space {
  padding-top: 20px;
  padding-bottom: 20px; }

.space-top {
  padding-top: 20px; }

.space-bottom {
  padding-bottom: 20px; }

.margin-clear {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.padding-top-clear {
  padding-top: 0 !important; }

.padding-bottom-clear {
  padding-bottom: 0 !important; }

.padding-clear {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.border-clear {
  border: none !important; }

.circle {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%; }

.separator {
  display: block;
  width: 60px;
  height: 5px;
  margin: 15px auto 15px;
  background-color: #cccccc;
  position: relative;
  border: 1px solid #cccccc; }

.separator:after {
  width: 30px;
  height: 5px;
  background: #333333;
  position: absolute;
  top: -1px;
  left: -1px;
  content: ""; }

.separator-2 {
  display: block;
  width: 30px;
  height: 5px;
  margin: 15px 0 15px;
  background-color: #cccccc;
  position: relative; }

.separator-2.right {
  margin-left: auto; }

.sidebar .separator {
  width: 30px;
  height: 5px;
  margin: 15px 0 20px;
  background-color: #666666;
  border-color: #666666; }

.sidebar .separator:after {
  content: "";
  background-color: transparent; }

.dark-bg .separator-2 {
  background-color: #999999; }

.default-bg .separator-2 {
  background-color: #ebebeb; }

.object-non-visible {
  opacity: 0;
  filter: alpha(opacity=0); }

.object-visible,
.touch .object-non-visible {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important; }

/* Targeting only Firefox for smoothest animations */
@-moz-document url-prefix() {
  .object-visible,
  .touch .object-non-visible {
    -webkit-transition: opacity 0.6s ease-in-out;
    -o-transition: opacity 0.6s ease-in-out;
    transition: opacity 0.6s ease-in-out; } }

.vertical-divider-left,
.vertical-divider-left-lg {
  border-left: 1px solid #eeeeee;
  padding-left: 50px;
  margin-left: 20px; }

.vertical-divider-right,
.vertical-divider-right-lg {
  border-right: 1px solid #eeeeee;
  padding-right: 50px;
  margin-right: 20px; }

.grid span {
  padding: 15px 5px;
  background-color: #fafafa;
  border: 1px solid #f3f3f3;
  display: block;
  text-align: center;
  margin-bottom: 10px; }

/* Medium devices (desktops, tablets, 992px and up) */
@media (min-width: 992px) {
  .margin-top-clear {
    margin-top: 0 !important; }
  .margin-bottom-clear {
    margin-bottom: 0 !important; } }

/* Large and Medium devices (desktops, tablets less than 1199px) */
@media (max-width: 1199px) {
  /* miscellaneous */
  .portfolio-item.vertical-divider-left-lg,
  .side.vertical-divider-left-lg {
    border-left: none;
    padding-left: 0px;
    margin-left: 0px; }
  .portfolio-item.vertical-divider-right-lg,
  .side.vertical-divider-right-lg {
    border-right: none;
    padding-right: 0px;
    margin-right: 0px; } }

/* Medium devices (desktops, tablets less than 991px) */
@media (max-width: 991px) {
  /* miscellaneous */
  .portfolio-item.vertical-divider-left,
  .side.vertical-divider-left {
    border-left: none;
    padding-left: 0px;
    margin-left: 0px; }
  .portfolio-item.vertical-divider-right,
  .side.vertical-divider-right {
    border-right: none;
    padding-right: 0px;
    margin-right: 0px; } }

/* 3 Sections
----------------------------------------------------------------------------- */
/* Footer
---------------------------------- */
.footer {
  font-size: 14px;
  color: #999999; }
  .footer h1,
  .footer h2,
  .footer h3,
  .footer h4,
  .footer h5,
  .footer h6 {
    color: #999999; }
  .footer h2 {
    font-size: 24px;
    margin-bottom: 30px; }

.light .footer,
.light .footer h1,
.light .footer h2,
.light .footer h3,
.light .footer h4,
.light .footer h5,
.light .footer h6 {
  color: #666666; }

/* Subfooter
---------------------------------- */
.subfooter {
  font-size: 14px; }
  .subfooter p {
    margin: 0; }

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  /* subfooter */
  .subfooter {
    text-align: center; } }

/* 4 Pages
----------------------------------------------------------------------------- */
/* Blog pages
---------------------------------- */
.blogpost {
  background-color: #fafafa;
  border: 1px solid #f3f3f3;
  margin: 0 0 70px;
  position: relative; }
  .blogpost .blogpost-body {
    padding: 20px 20px 0 20px;
    min-height: 140px; }
  .blogpost h2.title {
    margin-bottom: 0;
    margin-top: 0; }
  .blogpost .submitted {
    font-size: 12px;
    margin: 10px 0; }
  .blogpost .side {
    float: left;
    width: 80px; }
  .blogpost .post-info {
    float: left;
    top: 0;
    left: 25px;
    width: 80px;
    height: 90px;
    padding: 10px 15px;
    background-color: #364EA2;
    color: #ffffff;
    text-align: center;
    z-index: 3;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }
  .blogpost .side .post-info {
    float: none; }
  .blogpost .post-info .day {
    font-size: 34px;
    font-weight: 700;
    line-height: 1;
    display: block; }
  .blogpost .post-info .month {
    font-size: 16px;
    line-height: 1.2;
    display: inline-block; }
  .blogpost .side span.share {
    font-size: 13px;
    font-weight: 700;
    text-align: right;
    padding-top: 20px;
    display: block; }
  .blogpost .side .social-links {
    margin-top: 0; }
    .blogpost .side .social-links li {
      display: block;
      margin: 0; }
    .blogpost .side .social-links li a {
      width: 100%;
      height: auto;
      display: block;
      font-size: 20px;
      margin-bottom: 0;
      text-align: right;
      background-color: transparent;
      border-color: transparent;
      color: #364EA2; }
    .blogpost .side .social-links li.twitter a:hover {
      background-color: transparent;
      color: #55acee;
      border-color: transparent; }
    .blogpost .side .social-links li.facebook a:hover {
      background-color: transparent;
      color: #3b5998;
      border-color: transparent; }
    .blogpost .side .social-links li.googleplus a:hover {
      background-color: transparent;
      color: #dd4b39;
      border-color: transparent; }
  .blogpost .blogpost-content {
    padding: 0 0 10px;
    margin-left: 100px; }
  .blogpost footer {
    background-color: #f1f1f1;
    padding: 12px 20px;
    border-top: 1px solid #e8e8e8;
    font-size: 14px;
    line-height: 2;
    clear: both; }
  .blogpost ul.links {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0; }
    .blogpost ul.links li {
      display: inline-block; }
    .blogpost ul.links li a {
      color: #666666;
      display: inline-block; }
    .blogpost ul.links li a:hover {
      color: #364EA2; }
  .blogpost.full {
    background-color: transparent;
    border: none; }
    .blogpost.full footer {
      border: 1px solid #e8e8e8; }
    .blogpost.full .blogpost-body {
      padding: 20px 0; }
    .blogpost.full .submitted {
      margin: 0 0 20px 0; }
    .blogpost.full .overlay-container {
      margin-bottom: 20px; }

/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) {
  .blogpost .blogpost-content {
    margin-left: 70px; }
  .blogpost .side {
    width: 50px; }
  .blogpost .post-info {
    width: 50px;
    height: 60px;
    padding: 8px 8px;
    margin: 0 20px 5px 0; }
  .blogpost .post-info .day {
    font-size: 20px; }
  .blogpost .post-info .month {
    font-size: 11px;
    line-height: 1.2; } }

/* Affix */
#affix.affix {
  position: static; }

/* Medium devices (desktops, tablets, 992px and up) */
@media (min-width: 992px) {
  #affix.affix {
    position: fixed;
    top: 65px;
    width: 80px; }
  #affix.affix-bottom {
    position: absolute;
    width: 80px; } }

/* Affix Menu - Sidebar */
.sidebar.affix {
  position: static; }

/* Show and affix the side nav when space allows it */
/* Medium devices (desktops, tablets, 992px and up) */
@media (min-width: 992px) {
  .col-md-4 .sidebar.affix,
  .col-md-4 .sidebar.affix-bottom {
    width: 293px; }
  .col-md-3 .sidebar.affix,
  .col-md-3 .sidebar.affix-bottom {
    width: 213px; }
  .sidebar.affix {
    position: fixed;
    top: 20px; }
  .fixed-header-on .sidebar.affix {
    top: 65px; }
  .sidebar.affix-bottom {
    position: absolute; }
  .sidebar.affix-bottom .affix-menu,
  .sidebar.affix .affix-menu {
    margin-top: 0;
    margin-bottom: 0; }
  .affix-menu {
    padding-top: 20px; }
  h3.title ~ .affix-menu {
    padding-top: 0px; } }

/* Large devices (Large desktops 1200px and up) */
@media (min-width: 1200px) {
  .col-md-4 .sidebar.affix,
  .col-md-4 .sidebar.affix-bottom {
    width: 390px; }
  .col-md-3 .sidebar.affix,
  .col-md-3 .sidebar.affix-bottom {
    width: 263px; } }

/* Blog Masonry layout
---------------------------------- */
.masonry-grid .blogpost {
  margin-bottom: 30px; }
  .masonry-grid .blogpost .blogpost-body {
    padding: 15px 15px 0 15px; }
  .masonry-grid .blogpost h2.title {
    font-size: 24px; }
  .masonry-grid .blogpost .submitted {
    margin: 15px 0 10px; }
  .masonry-grid .blogpost .blogpost-content {
    margin-left: 0; }
  .masonry-grid .blogpost .post-info {
    width: 50px;
    height: 60px;
    padding: 8px 8px;
    margin: 0 20px 5px 0; }
  .masonry-grid .blogpost .post-info .day {
    font-size: 20px; }
  .masonry-grid .blogpost .post-info .month {
    font-size: 11px;
    line-height: 1.2; }

/* Medium desktop devices and tablets */
@media (min-width: 992px) and (max-width: 1199px) {
  .masonry-grid .blogpost footer {
    font-size: 12px; } }

/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) {
  .masonry-grid-item {
    max-width: 100%; } }

/* Blog Timeline layout
---------------------------------- */
.timeline {
  position: relative;
  padding: 40px 0;
  margin-top: 40px; }

.timeline-icon {
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  color: #f3f3f3;
  font-size: 40px;
  margin-left: -13px;
  top: -30px;
  line-height: 1; }

.timeline .timeline-item {
  padding: 0 15px; }

.timeline .blogpost .blogpost-body {
  padding: 15px 15px 0 15px; }

.timeline .blogpost h2.title {
  font-size: 24px; }

.timeline .blogpost .submitted {
  margin: 15px 0 10px; }

.timeline .blogpost footer {
  padding: 12px 15px; }

.timeline .blogpost .blogpost-content {
  margin-left: 0; }

.timeline .blogpost .post-info {
  width: 50px;
  height: 60px;
  padding: 8px 8px;
  margin: 0 20px 5px 0; }

.timeline .blogpost .post-info .day {
  font-size: 20px; }

.timeline .blogpost .post-info .month {
  font-size: 11px;
  line-height: 1.2; }

.timeline .timeline-item.pull-right + .timeline-date-label {
  padding-top: 80px; }

.timeline-date-label {
  float: none;
  clear: both;
  display: block;
  margin: 0px auto;
  text-align: center;
  margin-bottom: 80px;
  line-height: 1;
  width: 135px;
  position: relative;
  z-index: 2;
  color: #364EA2; }
  .timeline-date-label:after {
    background-color: #f3f3f3;
    height: 30px;
    width: 100%;
    position: absolute;
    bottom: -8px;
    z-index: -1; }

/* Medium desktop devices and tablets */
@media (min-width: 768px) and (max-width: 1199px) {
  .timeline .blogpost footer {
    font-size: 12px; } }

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .timeline .blogpost {
    margin-bottom: 0; }
  .timeline:before {
    background-color: #f3f3f3;
    width: 2px;
    height: 100%;
    content: "";
    position: absolute;
    left: 50%;
    display: block;
    margin-left: -1px;
    top: 0; }
  .timeline .timeline-item {
    position: relative;
    width: 50%;
    padding-left: 0;
    padding-right: 40px;
    margin-bottom: 80px;
    float: left;
    clear: left;
    z-index: 1; }
  .timeline .timeline-item.pull-right {
    position: relative;
    width: 50%;
    padding-left: 40px;
    padding-right: 0;
    margin-top: 80px;
    margin-bottom: 0;
    float: right;
    clear: right; }
  .timeline .timeline-item:after {
    content: "";
    font-family: "FontAwesome";
    right: 10px;
    background-color: #f3f3f3;
    left: auto;
    position: absolute;
    top: 20px;
    z-index: -1;
    width: 25px;
    height: 1px; }
  .timeline .timeline-item:before {
    content: "";
    position: absolute;
    background-color: #ffffff;
    border: 1px solid #364EA2;
    height: 10px;
    width: 10px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    top: 15px;
    right: -5px; }
  .timeline .timeline-item.pull-right:after {
    right: auto;
    left: 10px; }
  .timeline .timeline-item.pull-right:before {
    left: -5px; } }

/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) {
  .timeline .timeline-item.pull-right {
    float: none !important; } }

/* Comments
---------------------------------- */
.comments {
  margin-top: 60px; }

.comments h2.title {
  margin-bottom: 40px;
  border-bottom: 1px solid #d2d2d2;
  padding-bottom: 10px; }

.comment {
  font-size: 14px; }

.comment .comment {
  margin-left: 75px; }

.comment-avatar {
  margin-top: 5px;
  width: 55px;
  float: left; }

.comment-content {
  margin-left: 75px;
  border-bottom: 1px solid #d2d2d2;
  margin-bottom: 40px; }

.comment h3 {
  margin-top: 0;
  margin-bottom: 5px; }

.comment-meta {
  margin-bottom: 15px;
  color: #999999;
  font-size: 12px; }

.comment-meta a {
  color: #666666; }

.comment-meta a:hover {
  text-decoration: underline; }

.comment .btn {
  font-size: 12px;
  padding: 7px;
  min-width: 100px;
  margin-top: 5px;
  margin-bottom: -1px; }

.comment .btn i {
  padding-right: 5px; }

/* Portfolio
---------------------------------- */
.filters {
  margin: 0 0 30px 0; }
  .filters .nav-pills > li {
    margin-right: 2px;
    margin-bottom: 2px; }
  .filters .nav-pills > li + li {
    margin-left: 0px; }
  .filters.text-center .nav-pills > li {
    margin-right: 2px;
    margin-left: 2px;
    margin-bottom: 2px;
    display: inline-block;
    float: none; }

.list-item {
  margin-bottom: 40px; }
  .list-item h3.title {
    margin-top: 0; }

/* Portfolio Item
---------------------------------- */
.portfolio-item.side {
  font-size: 14px;
  margin-top: 30px; }
  .portfolio-item.side .social-links {
    margin: 0; }

/* Medium devices (desktops, tablets, 992px and up) */
@media (min-width: 992px) {
  .portfolio-item.side {
    margin-top: 125px; } }

/* Extra small devices (phones, less than 480px) */
@media (max-width: 480px) {
  /* Portfolio page */
  .filters .nav-pills > li {
    display: inline-block;
    width: 100%; } }

/* About Us pages
---------------------------------- */
.team-member small {
  display: block;
  margin-top: -5px; }

.team-member.box-style-1 {
  margin-top: 0; }

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .team-member.option-3 {
    overflow: hidden;
    margin-bottom: 20px; }
    .team-member.option-3 .overlay-container {
      float: left;
      width: 55%; }
    .team-member.option-3 .image-box-body {
      float: left;
      width: 45%;
      padding: 20px 10px 5px 15px; } }

/* Small and Medium devices (tablets, smartphones, laptops) */
@media (min-width: 768px) and (max-width: 1199px) {
  .team-member.option-3 .image-box-body {
    padding: 40px; } }

/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) {
  .team-member .image-box-body {
    padding: 15px 25px; }
  .team-member {
    max-width: 480px;
    margin: 20px auto 20px !important; } }

/* Coming Soon Page
---------------------------------- */
.fullscreen-bg {
  background: url("../images/fullscreen-bg.jpg") 50% 0px no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; }

.boxed .fullscreen-bg {
  background: none; }

.is-countdown {
  border: 1px solid transparent;
  background-color: transparent; }

.countdown-section {
  text-align: center;
  padding: 20px;
  margin: 10px 0; }

.countdown-amount {
  font-size: 36px;
  font-weight: 700;
  display: block; }

.countdown-period {
  display: block;
  font-size: 28px;
  line-height: 1.2;
  margin-top: 15px; }

/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) {
  .countdown-amount {
    font-size: 24px; }
  .countdown-period {
    font-size: 18px; } }

/* Extra small devices (phones, less than 480px) */
@media (max-width: 480px) {
  .countdown-period {
    font-size: 14px; } }

/* Shop pages
---------------------------------- */
.listing-item {
  margin: 0 0 20px 0;
  border: 1px solid #f1f1f1;
  position: relative;
  overflow: hidden; }

.listing-item-body {
  padding: 20px 15px 15px;
  -webkit-transition: ease-in-out all 0.25s;
  -o-transition: ease-in-out all 0.25s;
  transition: ease-in-out all 0.25s; }

.listing-item:hover .listing-item-body {
  background-color: #fafafa; }

.listing-item h3 {
  font-size: 18px; }

.listing-item p {
  color: #999999;
  font-size: 13px; }

.listing-item del,
.price del {
  font-size: 13px;
  line-height: 1;
  color: #cccccc; }

.elements-list > * {
  border-right: 1px solid #dddddd;
  margin: 5px 6px 5px 0;
  padding-right: 10px;
  line-height: 24px;
  height: 22px;
  display: inline-block; }

.elements-list > *:last-child {
  border-right: none;
  margin-right: 0;
  padding-right: 0; }

.price {
  font-size: 22px;
  color: #000000;
  font-weight: 300; }

.wishlist:hover i:before {
  content: "\f004"; }

.listing-item .badge {
  border-radius: 0;
  position: absolute;
  border-color: transparent;
  top: -1px;
  left: -1px;
  font-weight: 400; }

.sorting-filters {
  background-color: #f3f3f3;
  border: 1px solid #dddddd;
  padding: 20px 15px;
  margin-bottom: 40px; }

.sidebar .sorting-filters {
  margin-bottom: 0; }

.sorting-filters label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px; }

.sorting-filters form {
  margin: 0; }

.sorting-filters .btn {
  margin: 20px 0 0;
  min-width: 0;
  width: 100%; }

.sorting-filters .form-control,
.sorting-filters .form-control:focus {
  margin-bottom: 5px;
  -webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.12);
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.12);
  border-color: #f3f3f3; }

.sorting-filters .form-inline .form-control {
  width: 100%; }

.list-with-image {
  margin-bottom: 20px;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 5px; }

.list-with-image:last-child {
  border-bottom: none; }

.list-with-image .overlay-container {
  float: left;
  width: 80px;
  margin-bottom: 10px; }

.list-with-image h2,
.list-with-image p {
  margin-left: 100px; }

.list-with-image h2 {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 5px; }

.list-with-image p {
  font-size: 13px;
  color: #999;
  margin-bottom: 15px; }

.shop-banner {
  background-color: transparent;
  border-color: transparent; }

.shop-banner .tp-bullets {
  display: none !important; }

.shop-banner .sorting-filters {
  margin-bottom: 0; }

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .sorting-filters .form-inline .form-group {
    width: 18%;
    margin-right: 1.9%; }
  .sorting-filters .form-inline .form-group:last-child {
    margin-right: 0; }
  .shop-banner {
    position: relative; }
    .shop-banner .sorting-filters {
      position: absolute;
      bottom: 20px;
      z-index: 29;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.7);
      border: none; }
    .shop-banner .sorting-filters * {
      color: #000000; }
    .shop-banner .sorting-filters .form-control,
    .shop-banner .sorting-filters .form-control:focus {
      border-color: #aaaaaa; } }

/* Cart and Checkout pages
---------------------------------- */
.cart.table .price {
  width: 18%; }

.cart.table .amount,
.cart.table .remove,
.cart.table .quantity {
  width: 12%; }

.table .information {
  width: 80%; }

.cart.table .quantity input {
  width: 60px;
  padding: 6px; }

.cart.table .product {
  font-size: 15px; }

.cart.table .amount {
  text-align: right; }

.cart .price {
  font-size: 14px; }

.cart.table .product small {
  font-size: 12px;
  display: block;
  color: #999; }

.cart.table thead > tr > th {
  font-size: 16px; }

.cart.table .total-quantity,
.cart.table .total-amount {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 16px;
  font-weight: 700; }

.cart.table .total-amount {
  text-align: right; }

/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) {
  .cart.table thead > tr > th {
    font-size: 14px; }
  .cart.table .amount,
  .cart.table .price {
    width: 12%; }
  .cart.table .remove,
  .cart.table .quantity {
    width: 8%; }
  .cart.table > thead > tr > th,
  .cart.table > tbody > tr > th,
  .cart.table > tfoot > tr > th,
  .cart.table > thead > tr > td,
  .cart.table > tbody > tr > td,
  .cart.table > tfoot > tr > td {
    padding: 8px 10px; } }

/* Extra small devices (phones, less than 480px) */
@media (max-width: 480px) {
  .cart.table > thead > tr > th,
  .cart.table > tbody > tr > th,
  .cart.table > tfoot > tr > th,
  .cart.table > thead > tr > td,
  .cart.table > tbody > tr > td,
  .cart.table > tfoot > tr > td {
    padding: 8px 7px; } }

/* Cart dropdown block
---------------------------------- */
.cart .form-group {
  margin-bottom: 0; }

.header-top-dropdown .dropdown-menu.cart {
  padding: 0;
  min-width: 350px;
  font-size: 12px;
  right: -1px; }

.dropdown-menu.cart table {
  margin-bottom: 0; }

.dropdown-menu.cart .panel-body {
  padding: 0px 10px 0px; }

.dropdown-menu.cart .panel-body .btn {
  margin-left: 5px; }

.total-amount,
.total-quantity {
  font-size: 13px; }

.cart .table > tbody > tr > td,
.cart.table > tbody > tr > td {
  vertical-align: inherit; }

.cart .quantity {
  width: 50px;
  font-size: 11px; }

.invoice-container .cart .quantity {
  font-size: 14px; }

.cart .product {
  width: 220px;
  font-size: 13px; }

.cart .product .small {
  display: block;
  color: #999999; }

.cart .amount,
.total-amount {
  font-weight: 700; }

/* 5 Template Components
----------------------------------------------------------------------------- */
/* Buttons
---------------------------------- */
.btn {
  padding: 10px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  min-width: 160px;
  text-align: center;
  border-radius: 0;
  text-transform: uppercase;
  margin: 10px 0;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.btn:focus,
.btn:active {
  outline: none;
  box-shadow: none; }

.btn.btn-lg {
  padding: 17px 12px;
  font-size: 18px;
  min-width: 210px; }

.btn.btn-sm {
  padding: 8px;
  font-size: 12px;
  min-width: 105px; }

.btn.radius {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px; }

.moving {
  -webkit-animation: moving 1.7s ease-out infinite;
  -moz-animation: moving 1.7s ease-out infinite;
  -ms-animation: moving 1.7s ease-out infinite;
  -o-animation: moving 1.7s ease-out infinite;
  animation: moving 1.7s ease-out infinite;
  position: relative;
  top: -30px;
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important; }

.btn-remove {
  font-size: 10px;
  padding: 3px 15px;
  min-width: 0; }

@-webkit-keyframes moving {
  0% {
    top: -30px; }
  60% {
    top: 0px; } }

@keyframes moving {
  0% {
    top: -30px; }
  60% {
    top: 0px; } }

/* Dark Buttons */
.btn-dark {
  color: #f1f1f1;
  background-color: #333333;
  border-color: #333333; }

.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active,
.btn-dark.active,
.open .dropdown-toggle.btn-dark {
  color: #ffffff;
  background-color: #666666;
  border-color: #666666; }

/* Gray Buttons */
.btn-gray {
  color: #ffffff;
  background-color: #666666;
  border-color: #666666; }

.btn-gray:hover,
.btn-gray:focus,
.btn-gray:active,
.btn-gray.active,
.open .dropdown-toggle.btn-gray {
  color: #ffffff;
  background-color: #364EA2;
  border-color: #364EA2; }

/* Light Gray Buttons */
.btn-light-gray {
  color: #333333;
  background-color: #fafafa;
  border-color: #f3f3f3; }

.btn-light-gray:hover,
.btn-light-gray:focus,
.btn-light-gray:active,
.btn-light-gray.active,
.open .dropdown-toggle.btn-light-gray {
  color: #ffffff;
  background-color: #364EA2;
  border-color: #364EA2; }

.default-bg .btn-light-gray {
  color: #ffffff;
  background-color: transparent;
  border-color: #ffffff; }

.default-bg .btn-light-gray:hover,
.default-bg .btn-light-gray:focus,
.default-bg .btn-light-gray:active,
.default-bg .btn-light-gray.active,
.default-bg .open .dropdown-toggle.btn-light-gray {
  color: #333333;
  background-color: #fafafa;
  border-color: #fafafa; }

/* The color of default buttons is the color of current color scheme */
.btn-default {
  background-color: #364EA2;
  color: #ffffff !important;
  border-color: #2c5fb3; }

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  color: #ffffff !important;
  background-color: #2c5fb3;
  border-color: #2c5fb3; }

.default-bg .btn-default {
  background-color: rgba(0, 0, 0, 0.3);
  border-color: transparent; }

.default-bg .btn-default:hover,
.default-bg .btn-default:focus,
.default-bg .btn-default:active,
.default-bg .btn-default.active,
.default-bg .open .dropdown-toggle.btn-default {
  color: #ffffff !important;
  background-color: rgba(0, 0, 0, 0.5);
  border-color: transparent; }

.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default {
  background-color: #2c5fb3;
  border-color: #2c5fb3;
  opacity: 0.7;
  filter: alpha(opacity=70); }

/* Button with transparent bg */
.btn-white {
  background-color: transparent;
  color: #2c5fb3;
  border: 1px solid #364EA2; }

.footer .btn-white,
.subfooter .btn-white {
  color: inherit; }

.footer .btn-white:hover,
.subfooter .btn-white:hover {
  color: #ffffff; }

.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active,
.open .dropdown-toggle.btn-white {
  color: #ffffff;
  background-color: #2c5fb3;
  border-color: #2c5fb3; }

.default-bg .btn-white {
  color: #ffffff;
  background-color: transparent;
  border-color: #ffffff; }

.default-bg .btn-white:hover,
.default-bg .btn-white:focus,
.default-bg .btn-white:active,
.default-bg .btn-white.active,
.default-bg .open .dropdown-toggle.btn-white {
  color: #333333;
  background-color: #fafafa;
  border-color: #fafafa; }

.footer-content .btn-white {
  color: #999999;
  background-color: transparent;
  border-color: #555; }

.btn-default .badge {
  color: #666666;
  background-color: #ffffff;
  border: none; }

/*Button background transitions*/
.btn-hvr {
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

.hvr-sweep-to-right:before,
.hvr-sweep-to-left:before,
.hvr-sweep-to-bottom:before,
.hvr-sweep-to-top:before,
.hvr-bounce-to-right:before,
.hvr-bounce-to-left:before,
.hvr-bounce-to-bottom:before,
.hvr-bounce-to-top:before,
.hvr-radial-out:before,
.hvr-rectangle-out:before,
.hvr-shutter-out-horizontal:before,
.hvr-shutter-out-vertical:before {
  background: #2c5fb3; }

.hvr-radial-in:before,
.hvr-rectangle-in:before,
.hvr-shutter-in-horizontal:before,
.hvr-shutter-in-vertical:before {
  background: #fafafa; }

/* Links
---------------------------------- */
.link {
  padding-right: 23px;
  position: relative;
  display: inline-block;
  text-align: right; }
  .link:hover {
    text-decoration: none; }
  .link:hover span {
    text-decoration: underline; }
  .link:after {
    content: "\f178";
    font-family: "FontAwesome";
    position: absolute;
    right: 0;
    top: 2px; }

.link-light {
  color: #ffffff;
  text-decoration: none !important; }
  .link-light:hover {
    color: #ffffff;
    text-decoration: underline !important; }

.link-dark {
  color: #333333;
  text-decoration: none !important; }
  .link-dark:hover {
    color: #333333;
    text-decoration: underline !important; }

#footer:not(.light) .link-dark {
  color: #cdcdcd; }
  #footer:not(.light) .link-dark:hover {
    color: #cdcdcd; }

/* Forms
---------------------------------- */
form {
  margin: 20px 0; }

textarea {
  resize: vertical; }

.sidebar form {
  margin: 5px 0 20px 0; }

.form-control {
  border-radius: 0;
  background: #ffffff;
  height: 40px;
  padding-right: 30px;
  border-color: #acacac;
  color: #666666;
  position: relative;
  -webkit-appearance: none;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075); }

.form-control:focus {
  border-color: #666666;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075); }

select.form-control {
  padding-right: 5px; }

.form-control::-moz-placeholder {
  color: inherit; }

.form-control:-ms-input-placeholder {
  color: inherit; }

.form-control::-webkit-input-placeholder {
  color: inherit; }

.input-group-addon {
  background-color: #cacaca;
  border-color: #acacac;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px; }

.form-list > div {
  padding: 7px 15px;
  border-bottom: 1px solid #dddddd;
  background-color: #f3f3f3;
  margin: 6px 0;
  display: block; }

fieldset {
  padding: 10px 25px;
  margin: 15px 0px;
  border: 1px solid #dddddd;
  background-color: #fafafa; }
  fieldset legend {
    display: block;
    width: auto;
    padding: 3px 10px;
    margin-bottom: 0;
    font-size: 15px;
    line-height: inherit;
    color: #333;
    border: 1px solid #ddd;
    background-color: #ffffff; }
  fieldset .form-control,
  fieldset .form-control:focus {
    -webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.12);
    box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.12);
    border-color: #f3f3f3; }

.badge {
  padding: 2px 4px 3px;
  font-size: 10px;
  margin-left: 12px;
  top: -2px;
  position: relative;
  font-weight: 400;
  border-radius: 2px;
  background-color: transparent;
  color: inherit;
  border: 1px solid #666666; }

.dropdown .badge {
  background-color: transparent;
  color: inherit;
  position: absolute;
  right: 25px;
  top: 14px; }

.mega-menu .menu > li > a .badge {
  position: absolute;
  right: 10px;
  top: 9px; }

.dropdown .active > a > .badge,
.dropdown a:hover .badge,
.dropdown .open > a > .badge {
  border-color: #364EA2; }

.dark .dropdown .active > a > .badge,
.dark .dropdown a:hover .badge,
.dark .dropdown .open > a > .badge {
  border-color: #3d3d3d !important; }

@media (max-width: 767px) {
  .dropdown .badge {
    right: 50px;
    top: 15px; } }

/* Forms in all backgrounds */
.dark-bg .form-control {
  background-color: rgba(255, 255, 255, 0.2);
  border-color: #000000;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.45);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.45);
  color: #999999; }

.dark-bg .input-group-addon {
  background-color: rgba(255, 255, 255, 0.2);
  border-color: #000000; }

.dark-bg .form-control:focus {
  background-color: rgba(255, 255, 255, 0.25); }

.gray-bg .form-control,
.header-top-dropdown .form-control,
#footer.light .form-control {
  -webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.12);
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.12);
  border-color: #f1f1f1;
  background-color: #ffffff; }

.gray-bg .form-control:focus,
.header-top-dropdown .form-control:focus,
#footer.light .form-control:focus {
  border-color: #cacaca;
  -webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.09);
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.09); }

.dark .header-top-dropdown .form-control {
  background-color: rgba(255, 255, 255, 0.2);
  border-color: #333;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.45);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.45);
  color: #cdcdcd; }

.dark .header-top-dropdown form i {
  color: #cdcdcd; }

.dark .header-top-dropdown .form-control:focus {
  background-color: rgba(255, 255, 255, 0.25); }

.gray-bg .input-group-addon {
  background-color: #ececec;
  border: 1px solid #f1f1f1; }

.default-bg .form-control {
  border-color: #364EA2;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.35);
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.35);
  color: #ffffff; }

.default-bg .form-control:focus {
  border-color: #364EA2;
  background-color: rgba(0, 0, 0, 0.25); }

.default-bg .input-group-addon {
  background-color: #2c5fb3;
  border-color: #364EA2;
  color: #ffffff; }

.default-bg select.form-control,
.dark-bg select.form-control {
  color: #666666; }

footer:not(.light) .footer-content .form-control {
  border-color: #292929;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.55);
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.55); }

footer:not(.light) .footer-content .form-control:focus {
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.55);
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.55); }

footer:not(.light) .footer-content .input-group-addon {
  background-color: rgba(0, 0, 0, 0.5);
  border: transparent; }

select.form-control {
  -webkit-appearance: menulist; }

select[multiple].form-control {
  -webkit-appearance: listbox; }

/* Validation states */
.has-success .form-control,
.has-success .form-control:focus {
  border-color: #3c763d; }

.has-warning .form-control,
.has-warning .form-control:focus {
  border-color: #8a6d3b; }

.has-error .form-control,
.has-error .form-control:focus {
  border-color: #a94442; }

.default-bg .has-success .form-control,
.default-bg .has-warning .form-control,
.default-bg .has-error .form-control,
.default-bg .has-success .form-control:focus,
.default-bg .has-warning .form-control:focus,
.default-bg .has-error .form-control:focus {
  border-color: #ffffff;
  color: #ffffff; }

.default-bg .has-success .form-control-feedback,
.default-bg .has-warning .form-control-feedback,
.default-bg .has-error .form-control-feedback,
.default-bg .has-success .control-label,
.default-bg .has-warning .control-label,
.default-bg .has-error .control-label {
  color: #ffffff; }

.has-success .form-control,
.has-warning .form-control,
.has-error .form-control {
  -webkit-box-shadow: none;
  box-shadow: none; }

.has-success .form-control:focus,
.has-warning .form-control:focus,
.has-error .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none; }

.has-error .error {
  font-weight: 700;
  margin-bottom: 5px;
  display: block; }

/* forms with icons */
label ~ .form-control-feedback {
  top: 25px; }

.form-control-feedback {
  width: 40px;
  height: 40px;
  line-height: 42px !important;
  top: 0; }

/* Disabled forms */
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  opacity: 0.5;
  filter: alpha(opacity=50); }

/* Pager
---------------------------------- */
ul.pagination,
ul.pager {
  margin: 20px 0 40px;
  text-align: left; }

ul.pagination li {
  padding: 0;
  margin: 0 5px 0 0;
  border-bottom: none;
  font-size: 12px;
  display: inline-block;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px; }

ul.pagination li > a,
.pagination > li:first-child > a,
.pagination > li:last-child > a,
ul.pager li > a {
  padding: 10px 15px;
  border: 1px solid #f3f3f3;
  line-height: 1;
  background-color: #fafafa;
  display: inline-block;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  color: #666666; }

ul.pagination li > a:hover {
  background-color: transparent;
  color: #ffffff;
  background-color: #364EA2;
  border-color: #364EA2; }

ul.pagination li.active a,
.pagination > .active > a:hover,
.pagination > .active > a:focus {
  color: #ffffff;
  background-color: #364EA2;
  border-color: #364EA2;
  line-height: 1; }

ul.pagination li > a:focus {
  background: none; }

/* Breadcrumb
---------------------------------- */
.breadcrumb {
  background-color: transparent;
  margin-bottom: 0;
  font-size: 13px;
  padding: 8px 0; }

/* Tabs
---------------------------------- */
.nav-tabs {
  -webkit-box-shadow: inset 0px -1px 1px rgba(0, 0, 0, 0.04);
  box-shadow: inset 0px -1px 1px rgba(0, 0, 0, 0.04);
  border-bottom: 1px solid #f0f0f0;
  margin-top: 15px; }
  .nav-tabs > li > a {
    font-size: 13px;
    text-transform: uppercase;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    padding: 12px 25px;
    position: relative;
    color: #666666; }
  .nav-tabs > li > a:hover {
    border-color: #f0f0f0 #f0f0f0 #ebebeb;
    background-color: #fafafa; }
  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover,
  .nav-tabs > li.active > a:focus {
    color: #666666;
    background-color: #fafafa;
    border: 1px solid #f0f0f0;
    -webkit-box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.04);
    box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.04);
    border-bottom-color: transparent; }
  .nav-tabs > li.active > a:after {
    width: 3px;
    content: "";
    background-color: #364EA2;
    height: 103%;
    position: absolute;
    left: 0;
    top: 0; }

.tab-content {
  border: 1px solid #f0f0f0;
  padding: 20px;
  border-top: none;
  position: relative;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04);
  background-color: #fafafa;
  margin-bottom: 15px; }
  .tab-content h1 {
    font-size: 34px; }
  .tab-content:after {
    width: 3px;
    content: "";
    background-color: #364EA2;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }
  .tab-content.clear-style {
    border: none;
    padding: 10px 0 0 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent; }
  .tab-content.clear-style:after {
    width: 0;
    background-color: transparent;
    height: 100%; }

/* Small devices (tablets, smartphones) */
@media (min-width: 481px) and (max-width: 767px) {
  .nav-tabs > li > a {
    padding: 12px 10px;
    font-size: 11px;
    margin-right: 0; } }

/* Extra small devices (phones, less than 480px) */
@media (max-width: 480px) {
  .nav-tabs {
    background-color: #999999; }
    .nav-tabs > li {
      float: none; }
    .nav-tabs > li a {
      color: #ffffff;
      margin-right: 0;
      border-bottom: 1px solid #8f8f8f; }
    .nav-tabs > li > a:hover {
      color: #666666; }
    .nav-tabs > li > a:hover:after {
      width: 3px;
      content: "";
      background-color: #364EA2;
      height: 102%;
      position: absolute;
      left: 0;
      top: 0; } }

/* Horizontal tabs version 2 */
.tabs-style-2 .nav-tabs {
  border-bottom: 1px solid #364EA2; }

.tabs-style-2 .nav-tabs > li.active > a,
.tabs-style-2 .nav-tabs > li.active > a:hover,
.tabs-style-2 .nav-tabs > li.active > a:focus,
.tabs-style-2 .nav-tabs > li > a:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #364EA2;
  background-color: #364EA2;
  color: #ffffff; }

.tabs-style-2 .nav-tabs > li.active > a:after {
  width: 0;
  height: 0;
  background-color: transparent; }

.tabs-style-2 .tab-content {
  border: none;
  padding: 20px 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent; }

.tabs-style-2 .tab-content:after {
  width: 0px;
  background-color: transparent;
  height: 0; }

.tabs-style-2 .nav-tabs > li > a:hover:after {
  width: 0;
  background-color: transparent;
  height: 0; }

/* vertical tabs */
.vertical {
  margin: 20px 0; }
  .vertical.hc-tabs {
    margin: 40px 0; }
  .vertical .nav-tabs,
  .vertical .tab-content {
    display: table-cell;
    margin: 0;
    vertical-align: top; }
  .vertical .nav-tabs {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom-color: transparent;
    background-color: #999999; }
  .vertical .nav-tabs > li {
    float: none; }
  .vertical .nav-tabs > li a {
    margin: 0;
    padding: 15px 40px;
    white-space: nowrap;
    color: #ffffff;
    border-bottom: 1px solid #8f8f8f; }
  .vertical .nav-tabs > li > a:hover {
    border-color: #f0f0f0 #f0f0f0 #ebebeb;
    border-right-color: transparent;
    color: #666666;
    z-index: 2; }
  .vertical .nav-tabs > li > a:hover:after {
    width: 3px;
    content: "";
    background-color: #364EA2;
    height: 102%;
    position: absolute;
    left: 0;
    top: 0; }
  .vertical .nav-tabs > li.active > a,
  .vertical .nav-tabs > li.active > a:hover,
  .vertical .nav-tabs > li.active > a:focus {
    -webkit-box-shadow: -1px 0px 1px rgba(0, 0, 0, 0.04);
    box-shadow: -1px 0px 1px rgba(0, 0, 0, 0.04);
    border-right-color: transparent;
    border-bottom-color: #f0f0f0;
    color: #666666;
    z-index: 2;
    width: 101%; }
  .vertical .tab-content {
    border-top: 1px solid #f0f0f0;
    -webkit-box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.04);
    box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.04);
    left: -1px;
    z-index: 1;
    padding: 20px 30px; }
  .vertical .tab-content:after {
    background-color: transparent; }

.arrow {
  display: block;
  position: relative; }

.arrow i {
  font-size: 96px;
  top: -62px;
  position: absolute;
  line-height: 1;
  z-index: 2;
  left: 50%;
  margin-left: -27px; }

.arrow i:before {
  color: #fafafa;
  text-shadow: 0px -1px 1px #E9E9E9; }

/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) {
  .vertical .nav-tabs,
  .vertical .tab-content {
    display: block; } }

/* hc-tabs */
.hc-tabs .hc-tabs-top img {
  display: none; }

/* Nav pills
---------------------------------- */
.nav-pills > li > a {
  border-radius: 0;
  padding: 10px 15px;
  border: 1px solid transparent;
  color: #666666;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  background-color: #364EA2;
  color: #ffffff; }

.nav-pills.nav-stacked > li + li {
  margin-left: 0; }

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus,
.nav-pills > li > a:hover,
.nav-pills > li > a:focus {
  border: 1px solid #f3f3f3;
  color: #364EA2;
  background-color: #fafafa; }

.nav-pills.nav-stacked > li > a:after {
  font-family: "FontAwesome";
  content: "\f105";
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  right: 15px;
  top: 9px;
  color: #d1d1d1;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.nav-pills > li.active > a:after {
  color: #364EA2; }

.nav-pills > li.active > a:hover:after,
.nav-pills > li.active > a:focus:after,
.nav-pills > li > a:hover:after,
.nav-pills > li > a:focus:after {
  color: #364EA2;
  right: 10px; }

.footer-content .nav-pills > li > a {
  padding: 4px 0px;
  border-color: transparent;
  color: #999999;
  font-weight: 400; }

.footer-content .nav-pills > li:last-child > a {
  border-bottom: none; }

.footer-content .nav-pills > li.active > a,
.footer-content .nav-pills > li.active > a:focus,
.footer-content .nav-pills > li > a:focus {
  color: #999999;
  background-color: transparent;
  border-color: transparent; }

.footer-content .nav-pills > li.active > a:hover,
.footer-content .nav-pills > li > a:hover {
  color: #ffffff;
  background-color: transparent;
  border-color: transparent; }

.footer-content .nav-pills.nav-stacked > li > a:after {
  top: 3px; }

.footer-content .nav-pills.nav-stacked > li > a:after,
.footer-content .nav-pills > li.active > a:after,
.footer-content .nav-pills > li.active > a:hover:after,
.footer-content .nav-pills > li.active > a:focus:after,
.footer-content .nav-pills > li > a:hover:after,
.footer-content .nav-pills > li > a:focus:after {
  color: #999999; }

.light .footer-content .nav-pills > li > a,
.light .footer-content .nav-pills > li.active > a,
.light .footer-content .nav-pills > li.active > a:focus,
.light .footer-content .nav-pills > li > a:focus {
  color: #666666; }

.light .footer-content .nav-pills > li.active > a:hover,
.light .footer-content .nav-pills > li > a:hover {
  color: #2c5fb3; }

.light .footer-content .nav-pills.nav-stacked > li > a:after,
.light .footer-content .nav-pills > li.active > a:after,
.light .footer-content .nav-pills > li.active > a:hover:after,
.light .footer-content .nav-pills > li.active > a:focus:after,
.light .footer-content .nav-pills > li > a:hover:after,
.light .footer-content .nav-pills > li > a:focus:after {
  color: #666666; }

.nav-pills > .active > a > .badge {
  color: #2c5fb3;
  background-color: #ffffff;
  border-color: #2c5fb3; }

/* Process pills */
.process .nav-pills > li + li {
  margin-left: 60px;
  position: relative; }

.process .nav-pills > li + li:after {
  font-family: "FontAwesome";
  content: "\f101";
  position: absolute;
  top: 10px;
  left: -35px;
  width: 10px;
  background-color: #ffffff; }

/* Collapse
---------------------------------- */
.panel-group .panel {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  border: none; }

.panel-default > .panel-heading {
  padding: 0;
  outline: none;
  border: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  width: 100%; }

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border: 1px solid #f0f0f0;
  border-top: none;
  background-color: #fafafa; }

.panel-heading a {
  font-weight: 400;
  padding: 12px 35px 12px 15px;
  display: inline-block;
  width: 100%;
  background-color: #364EA2;
  color: #ffffff;
  position: relative;
  text-decoration: none; }

.panel-dark .panel-heading a,
.panel-dark .panel-heading a:hover {
  background-color: #333333; }

.panel-transparent .panel-default > .panel-heading,
.panel-transparent .panel-default > .panel-heading + .panel-collapse > .panel-body {
  background-color: transparent;
  color: inherit; }

.panel-transparent .panel-heading a {
  border: 1px solid #f1f1f1; }

.panel-transparent .panel-heading a.collapsed {
  color: inherit;
  background-color: transparent; }

.panel-heading a.collapsed {
  color: #ffffff;
  background-color: #999999; }

.panel-heading a:after {
  font-family: "FontAwesome";
  content: "\f147";
  position: absolute;
  right: 15px;
  font-size: 14px;
  font-weight: 300;
  top: 50%;
  line-height: 1;
  margin-top: -7px; }

.panel-heading a.collapsed:after {
  content: "\f196"; }

.panel-heading a:hover,
.panel-transparent .panel-heading a:hover {
  text-decoration: none;
  background-color: #364EA2;
  color: #ffffff; }

.panel-title a i {
  padding-right: 10px;
  font-size: 20px; }

/* Progress bars
---------------------------------- */
.progress {
  margin-bottom: 25px;
  background-color: #f1f1f1;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  height: 30px; }

.progress-bar {
  padding: 6px 0 5px 0;
  text-align: left; }

.progress-bar-default {
  background-color: #364EA2; }

.progress-bar-gray {
  background-color: #999999; }

.progress-bar-dark {
  background-color: #333333; }

.gray-bg .progress {
  background-color: #ffffff;
  -webkit-box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.1); }

.progress-bar span {
  position: relative;
  left: 15px; }

/* Circular Progress Bars
---------------------------------- */
.knob-container {
  margin: 10px;
  position: relative;
  display: inline-block; }

.knob-container canvas {
  width: 132px;
  height: 132px;
  margin: 0 auto; }

.knob-text {
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100%;
  margin-top: -14px; }

/* Large devices (Large desktops 1200px and up) */
@media (min-width: 1200px) {
  .knob-container canvas {
    width: 169px;
    height: 169px; } }

/* Alerts
---------------------------------- */
.alert {
  margin: 20px 0;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  position: relative;
  padding-left: 70px;
  overflow: hidden;
  z-index: 1;
  border: none; }

.alert:before {
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  width: 50px;
  height: 101%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 2; }

.alert-success {
  color: #2a5f2b;
  background-color: #bcdab0; }

.alert-info {
  color: #1a4e68;
  background-color: #c6e8fa; }

.alert-warning {
  color: #72582b;
  background-color: #f7f0ce; }

.alert:after {
  font-family: "FontAwesome";
  position: absolute;
  top: 10px;
  left: 17px;
  color: #ffffff;
  font-size: 20px;
  z-index: 3; }

.alert.alert-success:after {
  content: "\f00c"; }

.alert.alert-info:after {
  content: "\f05a"; }

.alert.alert-warning:after {
  content: "\f071"; }

.alert.alert-danger:after {
  content: "\f00d"; }

.close {
  font-size: 28px;
  font-weight: normal;
  filter: alpha(opacity=40);
  opacity: 0.4; }

/*Announcments
---------------------------------- */
.announcement {
  font-size: 13px; }
  .announcement .alert {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    padding: 5px 0;
    position: relative; }
  .announcement .alert:before {
    background-color: transparent; }
  .announcement .alert .close {
    position: absolute;
    top: 50%;
    right: 10px;
    z-index: 3;
    margin-top: -14px;
    text-shadow: none;
    opacity: 0.7;
    filter: alpha(opacity=70); }
  .announcement .alert .close:hover,
  .announcement .alert .close:focus {
    color: #ffffff;
    opacity: 1;
    filter: alpha(opacity=100); }
  .announcement p:last-child {
    margin-bottom: 0; }
  .announcement h2,
  .announcement h3,
  .announcement h4 {
    margin-top: 5px;
    margin-bottom: 0px;
    font-weight: 500; }

/* Modals
---------------------------------- */
.modal-open .page-wrapper {
  -webkit-filter: blur(3px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px); }

.modal-content {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px; }
  .modal-content .modal-header {
    background-color: #364EA2; }
  .modal-content .modal-title {
    font-size: 24px;
    color: #ffffff; }

.modal-footer .btn {
  margin: 0; }

/* Large devices (Large desktops 1200px and up) */
@media (min-width: 1200px) {
  .modal-lg {
    width: 1140px; } }

/* Popups
---------------------------------- */
.mfp-zoom-out-cur .page-wrapper {
  -webkit-filter: blur(3px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px); }

.mfp-bg {
  opacity: 0.6;
  filter: alpha(opacity=60); }

.mfp-figure:after {
  background-color: #ffffff; }

/* Images and Images overlay
---------------------------------- */
.fixed-image {
  background-position: center center;
  background-repeat: no-repeat;
  height: 400px; }

.no-touch .fixed-image {
  background-attachment: fixed; }

.fixed-image {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; }

.overlay-container {
  position: relative;
  display: block;
  overflow: hidden; }

.overlay {
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  right: -1px;
  background-color: rgba(241, 241, 241, 0.85);
  overflow: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: all linear 0.2s;
  -o-transition: all linear 0.2s;
  transition: all linear 0.2s; }
  .overlay a {
    display: inline-block;
    width: 50px;
    height: 50px;
    font-size: 26px;
    color: #364EA2;
    background-color: #ffffff;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -25px;
    margin-left: -25px; }
  .overlay span {
    position: absolute;
    display: block;
    bottom: 10px;
    text-align: center;
    width: 100%;
    color: #000000;
    font-style: italic; }

.overlay-links {
  display: block;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  margin-top: -25px;
  left: 50%;
  margin-left: -50px;
  font-size: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  -ms-transform: scale(0.4);
  -o-transform: scale(0.4);
  transform: scale(0.4);
  -webkit-transition: all 0.25s linear 0.05s;
  -o-transition: all 0.25s linear 0.05s;
  transition: all 0.25s linear 0.05s; }
  .overlay-links a {
    position: static;
    margin-top: 0;
    margin-left: 0; }
  .overlay-links a + a {
    left: 50px;
    color: #ffffff;
    background-color: #364EA2; }

.overlay a:hover {
  color: #ffffff;
  background-color: #333333;
  text-decoration: none; }

.overlay i {
  line-height: 50px; }

.box-style-1 .overlay i {
  font-size: 26px;
  margin: 0; }

a.overlay {
  background-color: rgba(255, 255, 255, 0.9);
  cursor: pointer; }

a.overlay:hover {
  text-decoration: none; }

.listing-item a.overlay {
  -webkit-transition: linear 0.25s;
  -o-transition: linear 0.25s;
  transition: linear 0.25s;
  background-color: rgba(255, 255, 255, 0.7);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }

.listing-item a.overlay:hover {
  text-decoration: none; }

a.overlay i {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 40px;
  line-height: 42px;
  color: #364EA2;
  margin-top: -22px;
  margin-left: -18px;
  text-align: center; }

a.overlay.small i {
  font-size: 18px;
  line-height: 1;
  margin-top: -8px;
  margin-left: -8px; }

.overlay .social-links {
  margin: 0;
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center; }

.overlay .social-links li {
  margin: 0 auto; }

.overlay .social-links li a {
  margin: 2px;
  width: 40px;
  height: 40px;
  font-size: 18px;
  position: static; }

.overlay .social-links li a:hover {
  background-color: #ffffff;
  border-color: #ffffff;
  text-decoration: none; }

.overlay .social-links li a i {
  line-height: 38px;
  font-size: 24px;
  margin: 0; }

.overlay-container:hover .overlay,
.overlay-container:hover .overlay-links {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }

/* Responsive iframe
---------------------------------- */
.tp-caption .embed-responsive {
  overflow: visible; }

/* Audio wrapper
---------------------------------- */
.audio-wrapper iframe {
  width: 100%;
  border: none;
  margin-bottom: 20px; }

/* Scroll Spy
---------------------------------- */
.scroll-spy {
  position: relative; }

/* 6 Navigations
----------------------------------------------------------------------------- */
/* first level menu */
.navbar.navbar-default {
  border-radius: 0;
  margin: 12px 0 5px;
  background-color: #364EA2;
  border-color: #4767d7; }

.header-small .navbar.navbar-default {
  margin: 6px 0 5px; }

.main-nav-container {
  background-color: #364EA2; }
  .main-nav-container .navbar-default .navbar-nav > li > a {
    color: #fafafa;
    border: 1px solid #364EA2; }

/* first level menu items */
.navbar-default .navbar-nav > li > a {
  color: #666666;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  padding: 12px 20px 12px 19px;
  z-index: 102;
  border: 1px solid transparent;
  bottom: -1px;
  -webkit-transition: none;
  -o-transition: none;
  transition: none; }

.navbar-default .navbar-nav > li {
  border-left: 1px solid #4767d7; }
  .navbar-default .navbar-nav > li:first-child {
    border-left: none; }

.navbar-default .navbar-nav > li > a:hover {
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  color: #364EA2;
  background-color: whitesmoke; }

/* first level active menu items on hover/focus */
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus {
  color: #364EA2;
  background-color: transparent; }

.navbar-default .navbar-nav > .active > a:hover {
  background-color: transparent; }

/* first level active menu items when opened */
.main-navigation .navbar-nav > .open > a,
.main-navigation .navbar-nav > .open > a:hover,
.main-navigation .navbar-nav > .open > a:focus {
  color: #666666;
  background-color: #fafafa;
  border: 1px solid #f1f1f1;
  border-bottom: 1px solid transparent;
  -webkit-box-shadow: -1px -1px 0px rgba(0, 0, 0, 0.03);
  box-shadow: -1px -1px 0px rgba(0, 0, 0, 0.03); }

/* first level active menu items when clicked */
.main-navigation .navbar-nav > .open > a:active,
.main-navigation .navbar-nav > .open.active > a:active {
  background-color: #ffffff;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: transparent;
  -webkit-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out; }

.main-navigation .navbar-nav > .open > a:active + ul,
.main-navigation .navbar-nav > .open.active > a:active + ul {
  display: none; }

/* second level menu */
.dropdown-menu {
  min-width: 240px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  padding: 0;
  margin: 0;
  background-color: #fafafa;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
  border: 1px solid #f1f1f1;
  z-index: 1001; }

/* second level menu items */
.dropdown-menu > li > a {
  padding: 12px 20px;
  font-size: 15px;
  color: #666666;
  border-bottom: 1px solid #f1f1f1;
  -webkit-transition: none;
  -o-transition: none;
  transition: none; }

.main-navigation .dropdown-menu > li > a {
  position: relative; }

/* second level menu items on focus/hover and when opened */
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus,
.dropdown-menu .menu > .active > a,
.dropdown-menu .menu > .active > a:hover,
.dropdown-menu .menu > .active > a:focus {
  background-color: #ffffff;
  color: #364EA2;
  border-color: #f1f1f1; }

/* third level menu */
.dropdown-menu .dropdown-menu {
  top: -1px;
  left: 100% !important;
  padding-top: 0;
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.08);
  border-top: 1px solid #f1f1f1; }

.dropdown-menu .dropdown-menu.to-left {
  right: 100% !important;
  left: auto !important; }

/*transparent header*/
.header.transparent:not(.dark) .navbar-nav > .open > a,
.header.transparent:not(.dark) .navbar-nav > .open > a:hover,
.header.transparent:not(.dark) .navbar-nav > .open > a:focus,
.header.transparent:not(.dark) .dropdown-menu {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-color: #fafafa !important; }

@media (min-width: 768px) {
  .header.transparent:not(.dark) .navbar-nav > li:not(.active) > a {
    color: #333333; } }

/* mega menu */
.mega-menu.dropdown {
  position: static; }

.mega-menu .dropdown-menu {
  left: 0;
  top: 96%;
  width: 100%;
  padding: 20px 30px; }

/* mega menu menu items */
.mega-menu .menu {
  list-style: none;
  padding: 0;
  margin: 0; }

.mega-menu h4.title {
  margin: 10px 0 5px;
  font-size: 14px;
  text-transform: uppercase; }

.mega-menu .menu > li > a {
  padding: 5px 0;
  font-size: 15px;
  color: #666666;
  border-bottom: 1px solid #f1f1f1;
  display: block;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  position: relative; }

.mega-menu .menu > li > a i,
.dropdown-menu > li > a i {
  padding-right: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.mega-menu .menu > li:last-child > a {
  border-bottom: none !important; }

.mega-menu .menu > li > a:hover i,
.dropdown-menu > li > a:hover i {
  padding-left: 5px; }

@media (min-width: 768px) {
  #t-main-navbar-nav-2 {
    width: 100%;
    text-align: center;
    float: none; }
    #t-main-navbar-nav-2 li {
      display: inline-block;
      float: none; }
    #t-main-navbar-nav-2 .dropdown-menu li {
      display: block; } }

/* mega menu menu items on focus/hover and when opened */
.mega-menu .menu > li > a:hover,
.mega-menu .menu > li > a:focus {
  background-color: #ffffff;
  color: #364EA2;
  border-color: #f1f1f1;
  text-decoration: none; }

/* arrows for parent menu items */
.dropdown > a:before {
  font-family: 'FontAwesome';
  content: "\f107";
  position: absolute;
  left: auto;
  top: 15px;
  right: 6px;
  line-height: 1;
  color: #d1d1d1; }

@media (min-width: 768px) {
  .main-navigation .navbar-nav > .dropdown.open > a,
  .main-navigation .navbar-nav > .dropdown.open > a:before {
    color: #364EA2; } }

.transparent.header:not(.dark) .dropdown > a:before {
  color: #666666; }

.navbar-default .navbar-nav > .active.dropdown > a:before {
  color: #364EA2; }

.navbar-default .navbar-nav > .dropdown.open > a:before {
  color: #e5e5e5; }

.dropdown .dropdown > a:before {
  content: "\f105";
  right: 10px;
  top: 15px;
  margin-left: 0;
  visibility: visible;
  color: #666666; }

.dropdown .dropdown.open > a:before,
.transparent.header:not(.dark) .dropdown .dropdown.open > a:before {
  color: #364EA2; }

/* mobile menu toggle button */
.navbar-default .navbar-toggle {
  border-color: #ccc; }

/* mobile menu toggle button on hover/focus */
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #fafafa;
  border-color: #364EA2; }

.navbar-default .navbar-toggle .icon-bar {
  background-color: #fafafa; }

.navbar-default .navbar-toggle:hover .icon-bar,
.navbar-default .navbar-toggle:focus .icon-bar {
  background-color: #4767d7; }

/*Dark Navigation*/
.dark.header .navbar.navbar-default {
  background-color: #27272A;
  border-color: #1f1f1f; }

/* Dark navigation first level menu items */
.dark.header .navbar-default .navbar-nav > li > a {
  color: #e1e1e1; }

/* Dark navigation first level active menu items on hover/focus */
.dark.header .navbar-default .navbar-nav > .active > a,
.dark.header .navbar-default .navbar-nav > .active > a:focus {
  color: #ffffff; }

/* Dark navigation first level active menu items when opened */
.dark.header .main-navigation .navbar-nav > .open > a,
.dark.header .main-navigation .navbar-nav > .open > a:hover,
.dark.header .main-navigation .navbar-nav > .open > a:focus {
  background-color: #27272a;
  border-color: #484848;
  border-bottom-color: #27272a;
  color: #ffffff; }

/*Dark navigation second level menu items*/
.dark.header .dropdown-menu {
  background-color: #27272a;
  border: 1px solid #484848; }

.dark.header-top .dropdown-menu {
  background-color: #36373c;
  border: 1px solid #3d3d3d;
  border-top-color: #36373c; }

.dark.header-top .dropdown-menu .divider {
  background-color: #454545; }

.dark.header .dropdown-menu > li > a {
  color: #cdcdcd;
  border-bottom: 1px solid #3d3d3d; }

/*Dark navigation second level menu items on focus/hover and when opened */
.dark.header .dropdown-menu > li > a:hover,
.dark.header .dropdown-menu > li > a:focus,
.dark.header .nav .open > a,
.dark.header .nav .open > a:hover,
.dark.header .nav .open > a:focus,
.dark.header .dropdown-menu > .active > a,
.dark.header .dropdown-menu > .active > a:hover,
.dark.header .dropdown-menu > .active > a:focus,
.dark.header .dropdown-menu .menu > .active > a,
.dark.header .dropdown-menu .menu > .active > a:hover,
.dark.header .dropdown-menu .menu > .active > a:focus {
  background-color: #36373C;
  color: #ffffff; }

/*Dark navigation mega-menu items*/
.dark.header h1,
.dark.header h2,
.dark.header h3,
.dark.header h4,
.dark.header h5,
.dark.header h6,
.dark.header p {
  color: #cdcdcd; }

.dark.header .divider {
  background-color: #3d3d3d; }

.dark.header .mega-menu .menu > li > a {
  color: #cdcdcd;
  border-bottom: 1px solid #3d3d3d; }

/*Dark navigation mega menu menu items on focus/hover and when opened */
.dark.header .mega-menu .menu > li > a:hover,
.dark.header .mega-menu .menu > li > a:focus {
  background-color: #36373c;
  color: #ffffff; }

/* Dark navigation arrows for parent menu items */
.dark.header .dropdown > a:before {
  color: #e1e1e1; }

.dark.header .navbar-default .navbar-nav > .active.dropdown > a:before {
  color: #ffffff; }

.dark.header .navbar-default .navbar-nav > .dropdown.open > a:before {
  color: #666666; }

.dark.header .dropdown .dropdown > a:before {
  color: #e1e1e1; }

/* Dark navigation mobile menu toggle button */
.dark.header .navbar-default .navbar-toggle {
  border-color: #666666; }

/* Dark navigation mobile menu toggle button on hover/focus */
.dark.header .navbar-default .navbar-toggle:hover,
.dark.header .navbar-default .navbar-toggle:focus {
  background-color: #36373c;
  border-color: #999999; }

.dark.header .navbar-default .navbar-toggle .icon-bar {
  background-color: #666666; }

.dark.header .navbar-default .navbar-toggle:hover .icon-bar,
.dark.header .navbar-default .navbar-toggle:focus .icon-bar {
  background-color: #999999; }

/* dropdown animations */
.animated.main-navigation .open .dropdown-menu,
#offcanvas.animated .open .dropdown-menu,
.open .dropdown-animation {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInDownSmall;
  animation-name: fadeInDownSmall; }

.open .dropdown-animation {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s; }

.animated.main-navigation .dropdown-menu .open .dropdown-menu,
#offcanvas.animated .dropdown-menu .open .dropdown-menu {
  -webkit-animation-name: fadeInLeftSmall;
  animation-name: fadeInLeftSmall; }

.animated.main-navigation .dropdown-menu .open .dropdown-menu.to-left,
#offcanvas.animated.offcanvas-right .dropdown-menu .open .dropdown-menu {
  -webkit-animation-name: fadeInRightSmall;
  animation-name: fadeInRightSmall; }

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .main-navigation .navbar-nav.navbar-right:last-child {
    margin-right: 0; }
  /* first level menu */
  .navbar.navbar-default,
  .dark.header .navbar.navbar-default {
    background-color: transparent;
    border-color: transparent; }
  /* layout fixes */
  .main-navigation .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-collapse {
    padding-right: 0px;
    padding-left: 0px; }
  .main-navigation .navbar-right .dropdown-menu {
    right: auto;
    left: 0; }
  .main-navigation .navbar-right > li:last-child > .dropdown-menu {
    right: 0;
    left: auto; } }

/* Medium desktop devices and tablets */
@media (min-width: 768px) and (max-width: 1199px) {
  .main-navigation .container-fluid {
    padding-right: 0px;
    padding-left: 0px; } }

/* Medium devices (tablets landscape to portrait) */
@media (min-width: 768px) and (max-width: 991px) {
  .main-navigation .navbar-nav {
    float: left !important; } }

/* Medium devices (desktops, tablets, 992px and up) */
@media (min-width: 992px) {
  .col-md-3 + .col-md-9 .mega-menu .dropdown-menu {
    left: -243px;
    width: 940px; } }

/* Medium desktop devices */
@media (min-width: 992px) and (max-width: 1199px) {
  .navbar-default .navbar-nav > li > a {
    padding-right: 19px; } }

/* Large devices (Large desktops 1200px and up) */
@media (min-width: 1200px) {
  /* first level menu item */
  .main-navigation .navbar-nav > li {
    margin: 0 0 0 15px; }
  .main-navigation .navbar-nav > li:first-child {
    margin: 0; }
  .col-md-3 + .col-md-9 .mega-menu .dropdown-menu {
    left: -293px;
    width: 1140px; } }

/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) {
  /* Mobile menu
  ------------------------------ */
  /* active items */
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #364EA2;
    background-color: transparent; }
  /* first level items */
  .navbar-default .navbar-nav > li > a {
    border-bottom: 1px solid #f1f1f1; }
  .subfooter .navbar-default .navbar-nav > li > a {
    border-bottom: 1px solid #141414; }
  .light .subfooter .navbar-default .navbar-nav > li > a {
    border-bottom: 1px solid #ddd; }
  .navbar-default .navbar-nav > li:last-child > a {
    border-bottom: 1px solid transparent; }
  /* first level items when opened */
  .main-navigation .navbar-nav > .open > a,
  .main-navigation .navbar-nav > .open > a:hover,
  .main-navigation .navbar-nav > .open > a:focus {
    border-color: transparent !important;
    -webkit-box-shadow: none;
    box-shadow: none; }
  /* first level items hover and focus states */
  .navbar-default .navbar-nav > li > a:hover,
  .navbar-default .navbar-nav > li > a:focus,
  .navbar-default .navbar-nav > .active > a:hover,
  .navbar-default .navbar-nav > .active > a:focus {
    color: #364EA2;
    background-color: #ffffff; }
  /* second level menu */
  .navbar-default .navbar-nav .open .dropdown-menu {
    background-color: #ffffff; }
  /* second level items */
  .navbar-default .navbar-nav .open .dropdown-menu > li > a,
  .navbar-default .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 12px 15px 12px 40px;
    color: #666666;
    border-color: #fafafa; }
  /* second level items on hover/focus */
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #364EA2; }
  /* third level items */
  .navbar-nav .open .dropdown-menu .dropdown-menu > li > a {
    padding-left: 60px; }
  /* four level items */
  .navbar-nav .open .dropdown-menu .dropdown-menu .dropdown-menu > li > a {
    padding-left: 80px; }
  /* Arrows for parent menu items */
  .dropdown > a:before {
    right: 32px; }
  .dropdown .dropdown > a:before {
    right: 36px; }
  /*Dark mobile menu
  ------------------------------ */
  .dark.header .navbar-default .navbar-collapse,
  .dark.header .navbar-default .navbar-form {
    border-color: #1f1f1f; }
  /* active items */
  .dark.header .navbar-default .navbar-nav .open .dropdown-menu > .active > a,
  .dark.header .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
  .dark.header .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
    background-color: #36373c; }
  /* first level items */
  .dark.header .navbar-default .navbar-nav > li > a {
    border-bottom: 1px solid #3c3c3e; }
  /* first level active items */
  .dark.header .navbar-default .navbar-nav > .active > a,
  .dark.header .navbar-default .navbar-nav > .active > a:focus {
    color: #ffffff;
    background-color: #36373C; }
  /* first level items hover and focus states */
  .dark.header .navbar-default .navbar-nav > li > a:hover,
  .dark.header .navbar-default .navbar-nav > li > a:focus,
  .dark.header .navbar-default .navbar-nav > .active > a:hover,
  .dark.header .navbar-default .navbar-nav > .active > a:focus {
    color: #ffffff;
    background-color: #36373c; }
  /* first level items when opened */
  .dark.header .main-navigation .navbar-nav > .open > a,
  .dark.header .main-navigation .navbar-nav > .open > a:hover,
  .dark.header .main-navigation .navbar-nav > .open > a:focus {
    color: #ffffff;
    background-color: #36373c; }
  /* second level menu */
  .dark.header .navbar-default .navbar-nav .open .dropdown-menu {
    background-color: #27272a;
    border: none; }
  /* second level items */
  .dark.header .navbar-default .navbar-nav .open .dropdown-menu > li > a,
  .dark.header .navbar-default .navbar-nav .open .dropdown-menu .dropdown-header {
    color: #cdcdcd;
    border-color: #3c3c3e; }
  /* second level items on hover/focus */
  .dark.header .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
  .dark.header .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #ffffff;
    background-color: #36373c; }
  .dark.header .navbar-default .navbar-nav > .dropdown.open > a:before {
    color: #ffffff; } }

/* Header top navigation
---------------------------------- */
.header-top .navbar.navbar-default {
  margin: 0;
  min-height: 30px; }

.header-top .navbar-default .navbar-nav > li > a {
  color: #999999;
  padding: 4px 20px; }

.header-top .navbar-default .navbar-nav > li {
  border-right: 1px solid #999999; }

.header-top .navbar-default .navbar-nav > li:last-child {
  border-right: none; }

/* Offcanvas navigation
---------------------------------- */
.offcanvas {
  background-color: #fafafa;
  border-color: #f1f1f1; }

.offcanvas-toggle-left {
  display: block;
  padding: 0;
  margin: 0;
  position: fixed;
  width: 40px;
  height: 40px;
  top: 0px;
  left: 0px;
  z-index: 1031;
  text-align: center; }

.offcanvas-toggle-left:after {
  font-family: "FontAwesome";
  content: "\f0c9";
  font-size: 22px;
  line-height: 35px;
  text-align: center;
  color: #333333; }

#offcanvas.in + .offcanvas-toggle-left:after {
  content: "\f00d";
  color: #999999; }

.offcanvas-toggle-right {
  display: block;
  padding: 0;
  margin: 0;
  position: fixed;
  width: 40px;
  height: 40px;
  top: 0px;
  right: 0px;
  z-index: 1031;
  text-align: center; }

.offcanvas-toggle-right:after {
  font-family: "FontAwesome";
  content: "\f0c9";
  font-size: 22px;
  line-height: 35px;
  text-align: center;
  color: #333333; }

#offcanvas.in + .offcanvas-toggle-right:after {
  content: "\f00d";
  color: #999999; }

/*First level menu items*/
#offcanvas .navbar-nav > li,
#offcanvas .navbar-nav {
  float: none;
  margin: 0; }

#offcanvas .navbar-nav > li > a {
  color: #333333;
  border-bottom: 1px solid #f5f5f5;
  padding-top: 10px;
  padding-bottom: 10px; }

#offcanvas .navbar-nav > li:last-child > a {
  border-bottom-color: transparent !important; }

#offcanvas .nav .open > a,
#offcanvas .nav .open > a:hover,
#offcanvas .nav .open > a:focus,
#offcanvas .nav > li > a:hover,
#offcanvas .nav > li > a:focus,
#offcanvas .navbar-nav > li.active > a {
  color: #e84c3d;
  background-color: #ffffff; }

/*Second level menu items*/
#offcanvas .dropdown-menu {
  position: static;
  width: 100%;
  float: none;
  box-shadow: none;
  border: none;
  background-color: #ffffff;
  margin: 0; }

#offcanvas.offcanvas-right .dropdown-menu {
  overflow: hidden; }

#offcanvas .dropdown-menu > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  border-color: #f7f7f7; }

#offcanvas.offcanvas-right .dropdown-menu > li > a {
  text-align: right;
  padding-left: 10px;
  padding-right: 20px; }

/*Third level menu items*/
#offcanvas .dropdown-menu .dropdown-menu > li > a {
  padding-left: 30px; }

#offcanvas.offcanvas-right .dropdown-menu .dropdown-menu > li > a {
  padding-left: 10px;
  padding-right: 30px; }

/*Fourth level menu items*/
#offcanvas .dropdown-menu .dropdown-menu .dropdown-menu > li > a {
  padding-left: 45px; }

#offcanvas.offcanvas-right .dropdown-menu .dropdown-menu .dropdown-menu > li > a {
  padding-left: 10px;
  padding-right: 45px; }

/*Fifth level menu items*/
#offcanvas .dropdown-menu .dropdown-menu .dropdown-menu .dropdown-menu > li > a {
  padding-left: 60px; }

#offcanvas.offcanvas-right .dropdown-menu .dropdown-menu .dropdown-menu .dropdown-menu > li > a {
  padding-left: 10px;
  padding-right: 60px; }

/*Nav arrows*/
#offcanvas .dropdown > a:before {
  right: 15px;
  top: 13px; }

#offcanvas.offcanvas-right .dropdown > a:before {
  right: auto;
  left: 15px;
  top: 13px; }

#offcanvas.offcanvas-right .dropdown .dropdown > a:before {
  content: "\f104"; }

/*Dark offcanvas nav*/
.dark.offcanvas-container .offcanvas {
  background-color: #27272a;
  border-color: #484848; }

.dark.offcanvas-container #offcanvas.in + .offcanvas-toggle-left:after,
.dark.offcanvas-container #offcanvas.in + .offcanvas-toggle-right:after {
  color: #e1e1e1; }

/*First level menu items*/
.dark.offcanvas-container #offcanvas .navbar-nav > li > a {
  color: #e1e1e1;
  border-bottom: 1px solid #3d3d3d; }

.dark.offcanvas-container #offcanvas .navbar-nav > li.open > a {
  border-bottom: 1px solid #515151 !important; }

.dark.offcanvas-container #offcanvas .nav .open > a,
.dark.offcanvas-container #offcanvas .nav .open > a:hover,
.dark.offcanvas-container #offcanvas .nav .open > a:focus,
.dark.offcanvas-container #offcanvas .nav > li > a:hover,
.dark.offcanvas-container #offcanvas .nav > li > a:focus,
.dark.offcanvas-container #offcanvas .navbar-nav > li.active > a {
  color: #ffffff;
  background-color: #36373c; }

/*Second level menu items*/
.dark.offcanvas-container #offcanvas .dropdown-menu {
  background-color: #36373c; }

.dark.offcanvas-container #offcanvas .dropdown-menu > li > a {
  border-color: #515151;
  color: #e1e1e1; }

.dark.offcanvas-container #offcanvas .dropdown-menu > li > a:hover,
.dark.offcanvas-container #offcanvas .dropdown-menu > li > a:focus {
  color: #ffffff;
  background-color: #36373c; }

/*Nav arrows*/
.dark.offcanvas-container .dropdown .dropdown.open > a:before {
  color: #ffffff; }

/* Subfooter navigation
---------------------------------- */
.subfooter .navbar {
  margin: 0;
  min-height: 0;
  background: transparent;
  border-color: transparent; }

.subfooter .navbar-default .nav > li > a {
  padding: 10px 10px;
  text-transform: none;
  line-height: 1;
  font-size: 14px;
  font-weight: 400; }

.subfooter .navbar-default .nav li:last-child a,
.light .subfooter .navbar-default .nav > li:last-child > a {
  border: none; }

.subfooter .navbar-default .nav > li > a:hover,
.subfooter .navbar-default .nav > .active > a:hover,
.subfooter .navbar-default .nav > .active > a:focus {
  background-color: transparent;
  text-decoration: underline;
  color: #e84c3d; }

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .subfooter .navbar-nav {
    float: none;
    margin: 15px 0;
    text-align: center; }
  .subfooter .navbar-nav > li {
    float: none;
    padding: 5px 0; }
  .subfooter .navbar-default .nav > li > a {
    padding: 0px 10px; } }

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .subfooter {
    text-align: left; }
  .subfooter .navbar-nav {
    float: right;
    margin: 0;
    text-align: left; }
  .subfooter .navbar-nav > li {
    float: left;
    padding: 0;
    margin-top: 4px; }
  .subfooter .navbar-default .nav > li > a {
    border-right: 1px solid #333333; }
  .light .subfooter .navbar-default .nav > li > a {
    border-right: 1px solid #999999; } }

/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) {
  /* subfooter nav */
  .subfooter .navbar-default .navbar-toggle:hover,
  .subfooter .navbar-default .navbar-toggle:focus {
    background-color: transparent;
    border-color: #888; }
  .light .subfooter .navbar-default .navbar-collapse {
    border-color: #ddd; }
  .subfooter .navbar-default .navbar-collapse {
    border-color: #000000; } }

/* 7 Blocks/Widgets
----------------------------------------------------------------------------- */
/* Logo
---------------------------------- */
.logo img {
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  max-width: 100%; }
  @media (min-width: 980px) {
    .logo img {
      width: 100%; } }

.logo img:hover {
  opacity: 0.85;
  filter: alpha(opacity=85); }

.main-container .logo img {
  margin: 0 auto; }

.header-small .logo img {
  max-height: 40px;
  margin: 2px 0 -4px 0; }

.text-center .logo img {
  margin: 0 auto; }

.site-slogan {
  font-size: 13px;
  font-style: italic;
  color: #999999; }

.transparent.header:not(.dark) .site-slogan {
  color: #333333; }

.dark.header .site-slogan {
  color: #cdcdcd; }

.transparent.dark.header .site-slogan {
  color: #ffffff; }

.main-container .site-slogan {
  color: inherit;
  text-align: center;
  margin-bottom: 20px; }

.logo-footer {
  margin: 25px 0 20px; }

.offcanvas .logo {
  margin: 20px 0 10px; }

.offcanvas .logo img {
  margin: 0 auto; }

.offcanvas .site-slogan {
  text-align: center;
  margin-bottom: 20px;
  margin-top: -10px; }

/* Medium devices (desktops, tablets less than 991px) */
@media (max-width: 991px) {
  .logo img,
  .header-small .logo img {
    margin: 0 auto; }
  .site-slogan {
    text-align: center; } }

/* Call to action
---------------------------------- */
.call-to-action {
  padding: 10px 25px;
  text-align: center; }
  .call-to-action .btn {
    margin: 10px 5px; }
  .call-to-action .btn.btn-lg {
    margin-top: 15px; }

/* Extra small devices (phones, less than 480px) */
@media (max-width: 480px) {
  .call-to-action .btn {
    display: block;
    width: 60%;
    margin: 10px auto; } }

/* Boxes
---------------------------------- */
.box {
  text-align: center;
  margin: 0 10px 10px 0;
  width: 60px;
  height: 60px;
  display: inline-block;
  font-size: 0;
  float: left;
  border: 1px solid transparent;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }
  .box.block {
    display: block;
    float: none;
    margin: 0 auto !important; }
  .box.block + h2 {
    margin-top: 0; }
  .box i {
    font-size: 36px;
    line-height: 60px; }
  .box.small {
    margin: 0 5px 5px 0;
    width: 45px;
    height: 45px; }
  .box.small i {
    font-size: 24px;
    line-height: 45px; }
  .box.gray-bg:hover {
    color: #364EA2; }
  .box.default-bg:hover {
    background-color: #ffffff;
    border: 1px solid #364EA2; }
  .box.default-bg:hover * {
    color: #364EA2; }
  .box.dark-bg:hover {
    background-color: #ffffff;
    border: 1px solid #666666; }
  .box.dark-bg:hover * {
    color: #666666; }

.box-style-1 {
  text-align: center;
  padding: 20px;
  margin: 20px 0; }
  .box-style-1 i {
    font-size: 90px;
    margin: 15px 0 5px;
    text-align: center; }
  .box-style-1:not(.team-member):hover i {
    color: #364EA2;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .box-style-1.default-bg:hover i,
  .box-style-1.dark-bg:hover i {
    color: inherit; }

.grid-space-10 .box-style-1 {
  margin: 10px 0; }

.box-style-2 {
  margin-top: 20px; }
  .box-style-2 i {
    font-size: 36px;
    line-height: 60px; }
  .box-style-2 .icon-container {
    float: left;
    width: 60px;
    height: 60px;
    text-align: center;
    border: 1px solid transparent;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }
  .box-style-2 .body {
    margin-left: 80px; }
  .box-style-2 h2 {
    font-size: 24px;
    margin-top: 0; }
  .box-style-2:hover .icon-container {
    border: 1px solid #666666; }
  .box-style-2:hover .default-bg {
    background-color: #ffffff;
    border: 1px solid #364EA2; }
  .box-style-2:hover .default-bg * {
    color: #364EA2; }
  .box-style-2:hover .dark-bg {
    background-color: #ffffff;
    border: 1px solid #666666; }
  .box-style-2:hover .dark-bg * {
    color: #666666; }
  .box-style-2:hover .white-bg * {
    color: #364EA2; }

.box-style-3 {
  margin-top: 20px; }
  .box-style-3 i {
    font-size: 22px;
    line-height: 45px; }
  .box-style-3 .icon-container {
    float: left;
    width: 45px;
    height: 45px;
    text-align: center;
    border: 1px solid transparent;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }
  .box-style-3 .body {
    margin-left: 80px; }
  .box-style-3 h2 {
    font-size: 20px;
    margin-top: 0; }
  .box-style-3:hover .icon-container {
    border: 1px solid #666666; }
  .box-style-3:hover .default-bg {
    background-color: #ffffff;
    border: 1px solid #364EA2; }
  .box-style-3:hover .default-bg * {
    color: #364EA2; }
  .box-style-3:hover .dark-bg {
    background-color: #ffffff;
    border: 1px solid #666666; }
  .box-style-3:hover .dark-bg * {
    color: #666666; }
  .box-style-3:hover .white-bg * {
    color: #364EA2; }

.panel-body i {
  width: 35px;
  text-align: center; }

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .box-style-3.right .icon-container {
    float: right; }
  .box-style-3.right .body {
    margin-right: 80px;
    text-align: right;
    margin-left: 0; } }

/* Image Boxes
---------------------------------- */
.image-box {
  background-color: #fafafa;
  border: 1px solid #f3f3f3; }
  .image-box h3.title {
    margin-top: 0; }
  .image-box .btn {
    white-space: normal;
    border-color: transparent; }
  .image-box .btn-block {
    margin: 0; }

.grid-space-0 .image-box {
  border: none; }

.image-box-body {
  padding: 20px 15px 15px; }

/* Tags cloud block
---------------------------------- */
.tags-cloud {
  font-size: 0; }

.tag {
  display: inline-block;
  margin: 0 3px 3px 0; }
  .tag a {
    padding: 5px 10px;
    color: #999999;
    display: block;
    font-size: 12px;
    background-color: #fafafa;
    border: 1px solid #f3f3f3; }
    .tag a:hover {
      color: #ffffff;
      background-color: #364EA2;
      border-color: #364EA2;
      text-decoration: none; }

footer:not(.light) .footer-content .tag a,
.dark-bg .tag a {
  background-color: transparent;
  border-color: #555;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

/* Social icons block
---------------------------------- */
.social-links {
  list-style: none;
  font-size: 0;
  padding: 0;
  margin: 40px 0 20px 0; }
  .social-links li {
    display: inline-block;
    margin: 0 2px 2px 0; }
  .social-links li a {
    width: 45px;
    height: 45px;
    display: block;
    text-align: center;
    font-size: 20px;
    background: #364EA2;
    border: 1px solid #364EA2;
    color: #ffffff; }
  .social-links.large li a {
    width: 60px;
    height: 60px;
    font-size: 24px; }
  .social-links.light li a {
    background: #fafafa;
    border: 1px solid #f3f3f3;
    color: inherit; }
  .social-links.dark li a {
    background: #454545;
    border: 1px solid #333333;
    color: #acacac; }
  .social-links.radius-5px li a {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px; }
  .social-links.circle li a {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%; }
  .social-links li a:hover {
    background-color: transparent;
    border-color: #2c5fb3;
    color: #2c5fb3; }
  .social-links.dark li a:hover {
    background: #fafafa;
    border: 1px solid #acacac;
    color: inherit; }
  .social-links.light li a:hover {
    border: 1px solid #d2d2d2;
    color: #333333; }
  .social-links li a i {
    line-height: 44px; }
  .social-links.large li a i {
    line-height: 60px; }

.header-top .social-links {
  margin: 0; }
  .header-top .social-links li {
    padding: 5px 7px 4px;
    border-right: 2px solid #e3e3e3;
    margin: 0; }
  .header-top .social-links li:hover {
    background-color: #fafafa; }
  .header-top .social-links li a {
    font-size: 20px; }
  .header-top .social-links li:last-child {
    border-right: none; }
  .header-top .social-links li a {
    border: none;
    width: 35px;
    height: 40px;
    color: #999999;
    background-color: transparent;
    border-color: transparent; }
  .header-top .social-links li a:hover {
    color: #2c5fb3; }
  .header-top .social-links li a i {
    line-height: 30px;
    padding-top: 5px; }
  .header-top .social-links .top-menu-link a {
    width: auto; }
    .header-top .social-links .top-menu-link a:hover {
      text-decoration: none;
      color: #4767d7; }

.dark.header-top .social-links li {
  border-right: 1px solid #3d3d3d; }
  .dark.header-top .social-links li:last-child {
    border-right: none; }
  .dark.header-top .social-links li:hover {
    background-color: transparent; }
  .dark.header-top .social-links li a {
    color: #999999; }

.footer-content .social-links {
  margin: 20px 0; }
  .footer-content .social-links li {
    margin: 0 5px 5px 0; }
    .footer-content .social-links li a {
      color: #666666;
      background-color: transparent;
      border-color: #666666; }
    .footer-content .social-links li a:hover {
      color: #ffffff;
      background-color: #666666;
      border-color: #666666; }

.social-links li.twitter a:hover,
.colored.social-links li.twitter a {
  background-color: #55acee;
  border-color: #55acee;
  color: #ffffff; }

.social-links li.skype a:hover,
.colored.social-links li.skype a {
  background-color: #00aff0;
  border-color: #00aff0;
  color: #ffffff; }

.social-links li.linkedin a:hover,
.colored.social-links li.linkedin a {
  background-color: #0976b4;
  border-color: #0976b4;
  color: #ffffff; }

.social-links li.googleplus a:hover,
.colored.social-links li.googleplus a {
  background-color: #dd4b39;
  border-color: #dd4b39;
  color: #ffffff; }

.social-links li.youtube a:hover,
.colored.social-links li.youtube a {
  background-color: #b31217;
  border-color: #b31217;
  color: #ffffff; }

.social-links li.flickr a:hover,
.colored.social-links li.flickr a {
  background-color: #ff0084;
  border-color: #ff0084;
  color: #ffffff; }

.social-links li.facebook a:hover,
.colored.social-links li.facebook a {
  background-color: #3b5998;
  border-color: #3b5998;
  color: #ffffff; }

.social-links li.pinterest a:hover,
.colored.social-links li.pinterest a {
  background-color: #cb2027;
  border-color: #cb2027;
  color: #ffffff; }

.social-links li.instagram a:hover,
.colored.social-links li.instagram a {
  background-color: #517fa4;
  border-color: #517fa4;
  color: #ffffff; }

.social-links li.vimeo a:hover,
.colored.social-links li.vimeo a {
  background-color: #aad450;
  border-color: #aad450;
  color: #ffffff; }

.social-links li.tumblr a:hover,
.colored.social-links li.tumblr a {
  background-color: #32506d;
  border-color: #32506d;
  color: #ffffff; }

.social-links li.soundcloud a:hover,
.colored.social-links li.soundcloud a {
  background-color: #ff3a00;
  border-color: #ff3a00;
  color: #ffffff; }

.social-links li.foursquare a:hover,
.colored.social-links li.foursquare a {
  background-color: #0072b1;
  border-color: #0072b1;
  color: #ffffff; }

.social-links li.dribbble a:hover,
.colored.social-links li.dribbble a {
  background-color: #ea4c89;
  border-color: #ea4c89;
  color: #ffffff; }

.social-links li.behance a:hover,
.colored.social-links li.behance a {
  background-color: #053eff;
  border-color: #053eff;
  color: #ffffff; }

.social-links li.vine a:hover,
.colored.social-links li.vine a {
  background-color: #00a478;
  border-color: #00a478;
  color: #ffffff; }

.social-links li.stumbleupon a:hover,
.colored.social-links li.stumbleupon a {
  background-color: #f74425;
  border-color: #f74425;
  color: #ffffff; }

.header-top .social-links li.twitter a:hover {
  color: #55acee;
  background-color: transparent;
  border-color: transparent; }

.header-top .social-links li.skype a:hover {
  color: #00aff0;
  background-color: transparent;
  border-color: transparent; }

.header-top .social-links li.linkedin a:hover {
  color: #0976b4;
  background-color: transparent;
  border-color: transparent; }

.header-top .social-links li.googleplus a:hover {
  color: #dd4b39;
  background-color: transparent;
  border-color: transparent; }

.header-top .social-links li.youtube a:hover {
  color: #b31217;
  background-color: transparent;
  border-color: transparent; }

.header-top .social-links li.flickr a:hover {
  color: #ff0084;
  background-color: transparent;
  border-color: transparent; }

.header-top .social-links li.facebook a:hover {
  color: #3b5998;
  background-color: transparent;
  border-color: transparent; }

.header-top .social-links li.pinterest a:hover {
  color: #cb2027;
  background-color: transparent;
  border-color: transparent; }

.header-top .social-links li.instagram a:hover {
  color: #517fa4;
  background-color: transparent;
  border-color: transparent; }

.header-top .social-links li.vimeo a:hover {
  color: #aad450;
  background-color: transparent;
  border-color: transparent; }

.header-top .social-links li.tumblr a:hover {
  color: #32506d;
  background-color: transparent;
  border-color: transparent; }

.header-top .social-links li.soundcloud a:hover {
  color: #ff3a00;
  background-color: transparent;
  border-color: transparent; }

.header-top .social-links li.foursquare a:hover {
  color: #0072b1;
  background-color: transparent;
  border-color: transparent; }

.header-top .social-links li.dribbble a:hover {
  color: #ea4c89;
  background-color: transparent;
  border-color: transparent; }

.header-top .social-links li.behance a:hover {
  color: #053eff;
  background-color: transparent;
  border-color: transparent; }

.header-top .social-links li.vine a:hover {
  color: #00a478;
  background-color: transparent;
  border-color: transparent; }

.header-top .social-links li.stumbleupon a:hover {
  color: #f74425;
  background-color: transparent;
  border-color: transparent; }

.social-links .dropdown > button {
  padding: 6px 15px 5px;
  font-size: 16px;
  color: #999999;
  text-align: center;
  min-width: 0;
  margin: 0;
  background-color: transparent;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.social-links .dropdown > button i {
  width: 15px; }

.social-links .dropdown.open > button > i:before {
  content: "\f00d";
  color: #364EA2; }

.social-links .dropdown-menu {
  z-index: 103;
  padding: 0px;
  min-width: 290px;
  margin-top: 1px;
  font-size: 0;
  box-shadow: none;
  background-color: #fafafa; }

.social-links .dropdown-menu li a,
.social-links .dropdown-menu li a i {
  padding: 0; }

/* Header top dropdowns
---------------------------------- */
.header-top-dropdown {
  float: right;
  font-size: 0;
  text-align: right; }
  .header-top-dropdown .dropdown > button {
    border-right: 1px solid #e3e3e3;
    border-left: 1px solid transparent; }
  .header-top-dropdown .dropdown-menu > li > a {
    color: whitesmoke; }
    .header-top-dropdown .dropdown-menu > li > a:hover {
      color: #364EA2; }

.dark .header-top-dropdown .dropdown > button {
  border-right: 1px solid #515151; }

.header-top-dropdown .dropdown:last-child > button {
  border-right-color: transparent; }

.header-top-dropdown .dropdown > button {
  padding: 9px 10px;
  margin: 0;
  font-size: 20px;
  color: #999999;
  text-align: center;
  min-width: 0;
  background-color: transparent;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  text-transform: none; }

.dark.header-top .header-top-dropdown .dropdown > button {
  border-right: 1px solid #3d3d3d; }

.dark.header-top .header-top-dropdown .dropdown:last-child > button {
  border-right-color: transparent; }

.header-top-dropdown .dropdown > button i {
  padding-right: 5px;
  width: 15px; }

.header-top-dropdown .dropdown.open > button,
.header-top-dropdown .dropdown > button:hover {
  color: #364EA2;
  background-color: #fafafa;
  border-right-color: #e3e3e3; }

.dark.header-top .header-top-dropdown .dropdown.open > button,
.dark.header-top .header-top-dropdown .dropdown > button:hover {
  color: #ffffff !important;
  background-color: #36373C;
  border-right-color: #3d3d3d; }

.dark.header .header-top-dropdown .dropdown.open > button,
.dark.header .header-top-dropdown .dropdown > button:hover {
  color: #ffffff !important;
  background-color: #36373C;
  border-right-color: #515151; }

.header-top-dropdown .dropdown:first-child.open > button,
.header-top-dropdown .dropdown:first-child > button:hover {
  border-left-color: #e3e3e3; }

.dark.header-top .header-top-dropdown .dropdown:first-child.open > button,
.dark.header-top .header-top-dropdown .dropdown:first-child > button:hover {
  border-left-color: #3d3d3d; }

.header .header-top-dropdown .dropdown > button {
  padding: 3px 10px; }

.header .header-top-dropdown .dropdown.open > button,
.header .header-top-dropdown .dropdown > button:hover {
  background-color: transparent; }

.header .header-top-dropdown .dropdown:first-child.open > button,
.header .header-top-dropdown .dropdown:first-child > button:hover {
  border-left-color: transparent; }

.header .header-top-dropdown .dropdown:last-child.open > button,
.header .header-top-dropdown .dropdown:last-child > button:hover {
  border-right-color: transparent; }

.header .header-top-dropdown .dropdown-menu {
  margin-top: 27px; }

.header-top-dropdown .dropdown-menu {
  z-index: 103;
  padding: 20px;
  min-width: 280px;
  margin-top: 0px; }

.header-top-dropdown .search-box .form-group {
  margin-bottom: 0; }

.header-top-dropdown form {
  margin: 0; }

.header-top-dropdown .login-form span {
  margin: 0 3px; }

.header-top-dropdown .login-form ul {
  padding-left: 0;
  list-style-position: inside;
  padding: 5px 0; }

.header-top-dropdown .login-form .social-links {
  display: inline-block;
  margin: 0; }

.dark .header-top-dropdown .login-form a {
  color: #cdcdcd;
  text-decoration: underline; }

.dark .header-top-dropdown .login-form a:hover {
  text-decoration: none; }

.header .header-top-dropdown {
  margin-top: 19px; }

.dark.header .header-top-dropdown {
  color: #cdcdcd; }

.fixed-header-on .header .header-top-dropdown {
  margin-top: 13px; }

.fixed-header-on .header .header-top-dropdown .dropdown-menu {
  margin-top: 19px; }

/* Small devices (tablets, 480px and below) */
@media (max-width: 480px) {
  .header-top-dropdown .btn-group {
    position: static; }
  .header-top-dropdown .dropdown-menu {
    right: 15px !important;
    width: 100%; } }

/* Full Width Content
---------------------------------- */
.full-width-section {
  position: relative; }

.full-text-container {
  position: absolute;
  top: 30%;
  padding: 20px 40px;
  background-color: rgba(255, 255, 255, 0.6);
  width: 100%; }

/* Large devices */
@media (min-width: 1200px) {
  .full-width-section {
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%; }
  .full-image-container {
    width: 50%;
    display: table-cell; }
  .full-text-container {
    display: table-cell;
    vertical-align: middle;
    width: 50%;
    padding: 0 50px;
    position: static; }
  .full-text-container * {
    max-width: 700px; }
  .full-text-container.left {
    text-align: right; }
  .full-text-container.left * {
    margin-left: auto; }
  .full-text-container p {
    margin-bottom: 10px; } }

@media (max-width: 1199px) {
  .full-width-section .separator-2.right {
    margin-left: 0; } }

@media (max-width: 991px) {
  .full-text-container {
    top: 0;
    height: 100%;
    padding: 0 20px; }
  .full-text-container h1 {
    font-size: 28px; } }

@media (max-width: 767px) {
  .full-text-container {
    position: static;
    padding: 20px; } }

/* Sidebar/footer gallery block
---------------------------------- */
.gallery.row {
  margin-left: -5px;
  margin-right: -5px; }

.gallery-item {
  padding-right: 5px;
  padding-left: 5px;
  margin-bottom: 10px; }

/* Form Block
---------------------------------- */
.form-block {
  width: 630px;
  border: 1px solid #f1f1f1;
  background-color: #ffffff;
  padding: 20px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px; }
  .form-block form {
    margin: 0; }
  .form-block ul {
    padding-left: 0;
    list-style-position: inside; }
  .form-block hr {
    margin-top: 10px;
    margin-bottom: 15px; }
  .form-block .social-links {
    margin: 10px 0 0 10px;
    display: inline-block; }

@media (max-width: 767px) {
  .form-block {
    width: 90%; } }

/* Latest tweets block
---------------------------------- */
ul.tweets {
  list-style: none;
  padding: 0; }
  ul.tweets li {
    margin-bottom: 20px;
    position: relative;
    padding-left: 35px; }
  ul.tweets li i {
    position: absolute;
    top: 4px;
    left: 0;
    width: 25px;
    height: 25px;
    text-align: center;
    color: #666666; }
  ul.tweets li p {
    margin-bottom: 5px; }
  ul.tweets li span {
    font-size: 12px;
    color: #666666; }

/* Testimonials
---------------------------------- */
.testimonial {
  margin: 25px 0; }

.well .testimonial,
.owl-item .testimonial {
  margin: 0; }

.testimonial h2 {
  margin-bottom: 25px; }

.testimonial-image {
  max-width: 160px;
  float: left;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%; }

.testimonial-body {
  padding: 15px; }

.testimonial-image + .testimonial-body {
  margin-left: 180px; }

.testimonial-info-1 {
  font-size: 13px;
  color: #000000;
  font-style: italic; }

.testimonial-info-2 {
  font-size: 13px;
  color: #000000;
  font-weight: 700; }

.dark-bg .testimonial-info-1,
.dark-bg .testimonial-info-2,
.default-bg .testimonial-info-1,
.default-bg .testimonial-info-2,
.dark-translucent-bg .testimonial-info-1,
.dark-translucent-bg .testimonial-info-2 {
  color: inherit; }

/* Extra small devices (phones, less than 480px) */
@media (max-width: 480px) {
  .testimonial-image {
    max-width: 80px; }
  .testimonial-image + .testimonial-body {
    padding: 0 15px;
    margin-left: 100px; } }

/* Isotope filtering
---------------------------------- */
.isotope-container {
  display: none;
  margin-bottom: 40px; }

.boxed .isotope-container.grid-space-0 .image-box img {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1); }

.isotope-item {
  margin-bottom: 30px; }

.grid-space-20 .isotope-item {
  margin-bottom: 20px; }

.grid-space-10 .isotope-item {
  margin-bottom: 0; }

/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) {
  .isotope-item {
    max-width: 100%; } }

/* Stats
---------------------------------- */
.stat-num {
  font-size: 36px;
  font-weight: 700;
  display: block; }

/* Pricing tables
---------------------------------- */
.pricing-tables {
  margin: 60px 0 0; }

.plan {
  text-align: center;
  border: 1px solid #c5c5c5;
  border-top: none;
  margin-bottom: 40px !important; }
  .plan + .plan {
    border-left: none; }
  .plan .header {
    padding: 10px 0;
    margin: 0 -1px; }
  .plan .header h3 {
    display: inline-block;
    font-size: 24px;
    margin: 0;
    line-height: 28px;
    font-weight: normal;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    text-shadow: 0px 1px 1px #494949; }
  .plan .header .price {
    display: inline-block;
    font-size: 16px;
    padding: 10px 0 0;
    font-weight: 400;
    color: inherit; }
  .plan .header .price span {
    text-transform: uppercase; }
  .plan ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 14px;
    list-style: none; }
  .plan ul li {
    padding: 12px 0px;
    font-size: 13px;
    line-height: 18px;
    text-shadow: 1px 1px 0 #ffffff; }
  .plan ul li a.pt-popover {
    color: #000000;
    border-bottom: 1px dashed #000000;
    position: relative; }
  .plan ul li a.pt-popover:hover {
    text-decoration: none; }
  .plan ul li a.pt-popover:after {
    position: absolute;
    top: 0px;
    left: -15px;
    font-family: "FontAwesome";
    content: "\f059";
    color: #DDDDDD;
    font-size: 12px; }
  .plan.best-value ul li {
    padding: 15px 0; }
  .plan .popover {
    width: 250px;
    font-size: 13px;
    text-align: center;
    border: none;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px; }
  .plan .popover-title {
    background-color: #364EA2;
    color: #ffffff;
    text-shadow: none;
    font-size: 13px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px; }
  .plan .popover-content {
    font-size: 12px; }
  .plan .btn {
    text-shadow: none; }

.gray .plan {
  border-top: 1px solid #c5c5c5;
  background-color: #f1f1f1;
  -webkit-box-shadow: -1px 0 0px rgba(0, 0, 0, 0.15);
  box-shadow: -1px 0 0px rgba(0, 0, 0, 0.15); }

.gray .col-sm-3 > .plan,
.gray .col-sm-4 > .plan {
  -webkit-box-shadow: none;
  box-shadow: none; }

.gray .plan .header {
  background: #fafafa;
  border: 1px solid #cacaca;
  width: 150px;
  height: 150px;
  margin: 15px auto;
  border-radius: 100%; }

.gray .plan .header h3 {
  color: #364EA2;
  display: block;
  margin-top: 30px; }

.gray .plan .header .price {
  font-size: 24px;
  padding-top: 5px; }

.gray .plan ul {
  background: #f1f1f1;
  border-top: 1px solid #e4e4e4; }

.gray .plan ul li {
  border-bottom: 1px solid #e4e4e4;
  border-top: 1px solid #f8f8f8;
  color: #333333; }

.white .plan {
  border-color: #f3f3f3; }

.white .plan .header {
  color: #ffffff;
  background: #364EA2; }

.white .plan .header h3 {
  color: #ffffff; }

.white .plan ul {
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  background-color: #fbfbfb; }

.white .plan ul li {
  border-bottom: 1px solid #f5f5f5; }

.stripped .header {
  background: #364EA2;
  border: none;
  -webkit-box-shadow: 0px 0px 60px #2c5fb3 inset;
  box-shadow: 0px 0px 60px #2c5fb3 inset;
  color: #ffffff; }

.stripped .header h3 {
  color: #ffffff; }

.stripped .plan ul {
  -webkit-box-shadow: 0px 0px 32px 0px #cacaca inset;
  box-shadow: 0px 0px 32px 0px #cacaca inset;
  background: #fdfdfd; }

.stripped .plan ul li {
  border: 1px solid #ececec;
  border-top: none;
  color: #666666; }

.stripped .plan ul li:nth-child(even) {
  -webkit-box-shadow: 0px 0px 40px #e4e4e4 inset;
  box-shadow: 0px 0px 40px #e4e4e4 inset;
  background: #ffffff;
  border-right: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2; }

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .best-value.plan {
    top: -30px;
    position: relative; } }

/* Google maps
---------------------------------- */
#map-canvas {
  height: 450px; }

.sidebar #map-canvas {
  height: 350px; }

#map-canvas img {
  max-width: inherit; }

/* Scroll to top
---------------------------------- */
.scrollToTop {
  color: #ffffff;
  position: fixed;
  bottom: 5px;
  right: 5px;
  width: 50px;
  height: 50px;
  text-align: center;
  cursor: pointer;
  background-color: #364EA2;
  z-index: 1005;
  display: none; }
  .scrollToTop i {
    line-height: 50px;
    font-size: 24px; }

/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) {
  .scrollToTop {
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.4); }
    .scrollToTop i {
      line-height: 40px;
      font-size: 20px; } }

/* 8 Main Slideshow
----------------------------------------------------------------------------- */
/* Slider Revolution
---------------------------------- */
.slider-banner-container {
  width: 100%;
  position: relative;
  padding: 0; }

.slider-banner,
.slider-banner-2,
.slider-banner-3,
.slider-banner-fullscreen {
  width: 100%;
  position: relative; }

.slider-banner > ul,
.slider-banner-2 > ul,
.slider-banner-3 > ul,
.slider-banner-fullscreen > ul {
  display: none; }

.tp-loader.spinner0 {
  background: url("../images/loader.gif") no-repeat center center;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-animation: none;
  -moz-animation: none;
  -ms-animation: none;
  -o-animation: none;
  animation: none;
  animation: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  width: 44px;
  height: 44px;
  margin-top: -22px;
  margin-left: -22px; }

.tp-loader.spinner4 .dot1,
.tp-loader.spinner4 .dot2 {
  background-color: #666666;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.8); }

.slideshow-boxed {
  padding: 20px 0 60px; }
  .slideshow-boxed .slider-banner-container {
    padding: 10px;
    background: #ffffff;
    border: 1px solid #eaeaea; }
  .slideshow-boxed .tp-caption {
    margin-left: 20px !important; }

/* Slider Revolution captions */
.tp-caption {
  z-index: 5; }
  .tp-caption a.btn,
  .tp-caption a.btn:hover {
    color: #ffffff; }

.default_bg {
  background-color: #364EA2;
  color: #ffffff; }

.white_bg {
  background-color: #ffffff;
  color: #333333; }

.dark_gray_bg {
  background-color: #666666;
  color: #ffffff; }

.light_gray_bg {
  background-color: #fafafa;
  color: #333;
  border: 1px solid #f3f3f3 !important;
  border-left: none !important; }

.tp-caption.small_thin_white {
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
  font-weight: 300;
  text-shadow: 1px 1px 1px #000000; }

.tp-caption.small_thin_dark {
  font-size: 18px;
  line-height: 26px;
  color: #000000;
  font-weight: 400; }

.tp-caption.large {
  font-size: 36px;
  padding: 0px 15px; }

.tp-caption.medium {
  font-size: 22px;
  padding: 2px 10px; }

.tp-caption.small {
  font-size: 14px;
  padding: 5px; }

.tp-caption.black,
.tp-caption .btn.black,
.tp-caption .btn.black:hover {
  color: #333333; }

/* Large devices (Large desktops less than 1199px) */
@media (max-width: 1199px) {
  .tp-caption {
    margin-left: 20px !important; } }

/* Revolution Nav Controls */
.tparrows.round {
  background-image: none;
  width: 50px;
  height: 50px;
  text-align: center;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5);
  top: 50% !important;
  -webkit-transition: all linear 0.2s;
  -o-transition: all linear 0.2s;
  transition: all linear 0.2s;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%; }
  .tparrows.round:before {
    font-family: 'fontello';
    font-size: 24px;
    content: '\e87c';
    line-height: 52px;
    display: inline-block; }
  .tparrows.round.tp-rightarrow:before {
    content: '\e87d'; }
  .tparrows.round:hover {
    color: #ffffff;
    background-color: #364EA2; }

.slideshow-boxed .slider-banner-container .tparrows {
  opacity: 0;
  filter: alpha(opacity=0); }

.slideshow-boxed .slider-banner-container:hover .tparrows {
  opacity: 1;
  filter: alpha(opacity=100); }

.tp-bullets {
  z-index: 100; }

.tp-bullets.simplebullets.round .bullet {
  background-image: none;
  width: 14px;
  height: 14px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  margin: 0 3px;
  -webkit-transition: all linear 0.2s;
  -o-transition: all linear 0.2s;
  transition: all linear 0.2s;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%; }

.bullets-with-bg + .tp-bullets.preview4 {
  height: auto;
  background-color: white;
  padding: 5px 15px;
  bottom: -6px !important;
  border-radius: 3px; }

.tp-bullets.simplebullets.round .bullet:hover,
.tp-bullets.simplebullets.round .bullet.selected {
  background-color: #364EA2; }

.tp-bannertimer {
  background-image: none;
  background-color: rgba(232, 76, 61, 0.7);
  z-index: 100;
  display: none; }

.slideshow-boxed .tp-bullets {
  bottom: -33px !important; }

.slideshow-boxed .bullets-with-bg + .tp-bullets.preview4 {
  padding: 7px 15px 2px;
  bottom: -45px !important; }

.slideshow-boxed .slider-banner-container .tp-rightarrow {
  right: 10px !important; }

.slideshow-boxed .slider-banner-container .tp-leftarrow {
  left: 10px !important; }

.slideshow-boxed .tp-bullets.hidebullets {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important; }

.tparrows.preview2 {
  background-color: rgba(0, 0, 0, 0.3);
  color: #ffffff; }
  .tparrows.preview2:hover {
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff; }
  .tparrows.preview2:after {
    margin-top: -18px;
    left: 22px;
    color: #ffffff;
    font-family: "FontAwesome";
    content: "\f104";
    font-size: 36px;
    line-height: 1; }

.tp-rightarrow.preview2:after {
  content: "\f105";
  left: auto;
  right: 22px; }

.tparrows.preview2:hover:after {
  color: #ffffff; }

.tparrows.preview2 .tp-arr-titleholder {
  color: #ffffff;
  font-size: 16px; }

/* Medium devices (phones, tablets, laptops) */
@media (min-width: 768px) and (max-width: 979px) {
  .tparrows.preview2:after {
    margin-top: -13px;
    left: 15px;
    font-size: 24px; }
  .tp-rightarrow.preview2:after {
    left: auto;
    right: 15px; } }

@media (max-width: 767px) {
  .tparrows.preview2 {
    display: none; } }

/* Extra small devices (phones, less than 480px) */
@media (max-width: 480px) {
  .tparrows,
  .tp-bullets {
    display: none; } }

/* 9 Owl carousel
----------------------------------------------------------------------------- */
.owl-carousel {
  z-index: 4; }

.owl-carousel .owl-item > div {
  margin: 10px 0 20px; }

.owl-carousel.carousel .owl-item > div,
.owl-carousel.carousel-autoplay .owl-item > div {
  margin: 10px 20px 20px 0; }

.owl-carousel.clients .owl-item > div {
  margin: 0px 30px 0px 0; }

.well.owl-carousel .owl-item > div {
  margin: 10px 0 0; }

.tab-content .owl-carousel .owl-item > div {
  margin: 0 0 20px; }

/* caption */
.caption {
  background-color: rgba(255, 255, 255, 0.85);
  padding: 15px;
  position: absolute;
  bottom: 80px;
  left: 80px;
  width: 40%;
  border: 1px solid #ffffff; }

.caption .title {
  margin-top: 0; }

/* Medium devices (desktops, tablets less than 991px) */
@media (max-width: 991px) {
  .caption {
    width: 90%;
    bottom: 40px;
    left: 40px; } }

/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) {
  .caption {
    width: 100%;
    bottom: 30px;
    left: 0;
    position: relative;
    background-color: #fafafa;
    bottom: 0;
    padding-bottom: 35px; } }

/* owl controls */
.owl-nav {
  width: 100%;
  margin-bottom: 50px; }

.owl-nav div {
  display: inline-block;
  zoom: 1;
  padding: 3px 10px;
  font-size: 12px;
  position: absolute;
  width: 30px;
  height: 30px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.owl-prev {
  left: 0;
  background-color: #364EA2;
  color: #f1f1f1; }

.owl-next {
  left: 30px;
  background-color: #f1f1f1;
  color: #364EA2; }

.owl-prev:hover,
.owl-next:hover,
.gray-bg .owl-next:hover {
  background-color: #333333;
  color: #f1f1f1; }

.gray-bg .owl-next {
  background-color: #ffffff; }

.owl-nav div:after {
  font-family: "fontello";
  font-size: 18px;
  line-height: 26px; }

.owl-prev:after {
  content: "\e87c"; }

.owl-next:after {
  content: "\e87d"; }

.no-touch .owl-nav div:hover {
  filter: Alpha(Opacity=100);
  opacity: 1;
  text-decoration: none; }

.content-slider-with-controls {
  margin-bottom: 20px; }

.content-slider-with-controls .owl-nav,
.content-slider-with-controls-autoplay .owl-nav {
  margin-bottom: 0; }

.content-slider-with-controls .owl-nav div,
.content-slider-with-controls-autoplay .owl-nav div {
  top: 50%;
  margin-top: -15px;
  background-color: #364EA2;
  color: #f1f1f1; }

.content-slider-with-controls .owl-nav div.owl-next,
.content-slider-with-controls-autoplay .owl-nav div.owl-next {
  left: auto;
  right: 0; }

.content-slider-with-controls .owl-prev:hover,
.content-slider-with-controls .owl-next:hover,
.content-slider-with-controls-autoplay .owl-prev:hover,
.content-slider-with-controls-autoplay .owl-next:hover {
  background-color: #f1f1f1;
  color: #364EA2; }

.content-slider-with-controls-bottom .owl-dots {
  bottom: -30px;
  z-index: -1; }

.content-slider-with-controls-bottom .owl-dots .owl-dot span {
  background: #acacac; }

.owl-dots {
  position: absolute;
  bottom: 25px;
  text-align: center;
  margin: 0 auto;
  display: block;
  width: 100%; }

.owl-dots .owl-dot {
  display: inline-block;
  zoom: 1; }

.owl-dots .owl-dot span {
  display: block;
  width: 12px;
  height: 12px;
  margin: 5px 4px;
  background: #ffffff; }

.owl-dots .owl-dot.active span,
.owl-dots .owl-dot:hover span {
  background: #364EA2; }

#t-admin-megamenu img {
  display: inline; }
  #t-admin-megamenu img.nav-logo {
    height: 25px; }

#t-admin-megamenu .dropdown > a::before {
  content: none;
  right: 0; }

#home {
  /*! events */ }
  #home .main-container {
    background-color: #364EA2;
    padding: 0; }
    #home .main-container .btn {
      border: none; }
  #home .events-wrap {
    background-color: #ebebeb;
    padding: 20px 0px 35px; }
    #home .events-wrap h2 {
      font-weight: bold; }
    #home .events-wrap .events-title {
      padding: 60px 0 15px; }
      #home .events-wrap .events-title h2 {
        margin: 0; }
    #home .events-wrap .calendar-img h5 {
      background: url(../../images/event-icon.png) no-repeat top center;
      color: #000;
      text-align: center;
      font-family: 'Open Sans', sans-serif;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 40px;
      padding: 24px 0; }
    #home .events-wrap .event-item p {
      text-align: center;
      color: #3C6FA9;
      text-transform: uppercase;
      font-size: 20px;
      line-height: 22px;
      font-weight: 600; }
    #home .events-wrap .event-item h3 {
      line-height: 17px;
      text-align: center;
      margin-bottom: 20px; }
      #home .events-wrap .event-item h3 a {
        color: #102C40;
        font-family: 'Open Sans', sans-serif;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 20px; }
    #home .events-wrap .view-more {
      padding: 5px 10px;
      border: 1px solid #f2f2f2;
      position: relative;
      font-family: 'Open Sans Condensed', sans-serif;
      text-transform: uppercase;
      font-weight: 700;
      color: #0778B2;
      float: right; }
      #home .events-wrap .view-more img {
        margin-left: 20px; }

.footer .footer-text {
  margin-top: 25px;
  text-align: center; }
  @media (min-width: 768px) {
    .footer .footer-text {
      text-align: left; } }

.footer .logo-footer {
  text-align: center; }
  .footer .logo-footer img {
    display: inline-block; }

.footer .nav.navbar-nav.t-main-navbar-nav > li {
  float: none; }
  .footer .nav.navbar-nav.t-main-navbar-nav > li > a {
    padding: 0;
    text-transform: uppercase;
    color: #4767d7;
    font-weight: 700;
    line-height: 30px;
    font-size: 16px; }
    .footer .nav.navbar-nav.t-main-navbar-nav > li > a:hover {
      color: whitesmoke;
      background-color: transparent; }

.mg-top-10 {
  margin-top: 10px; }

.mg-top-20 {
  margin-top: 20px; }

.mg-top-30 {
  margin-top: 30px; }

.mg-top-40 {
  margin-top: 40px; }

.mg-top-50 {
  margin-top: 50px; }

.mg-bottom-10 {
  margin-bottom: 10px; }

.mg-bottom-20 {
  margin-bottom: 20px; }

.mg-bottom-30 {
  margin-bottom: 30px; }

.mg-bottom-40 {
  margin-bottom: 40px; }

.mg-bottom-50 {
  margin-bottom: 50px; }

#side-events {
  margin: 15px 0; }
  #side-events h2 {
    color: #3C6FA9;
    border-bottom: 1px solid #ececec;
    padding-bottom: 15px; }
  #side-events .event-txt {
    float: left;
    width: 73%;
    padding-top: 10px;
    margin-bottom: 15px; }
  #side-events .calendar-img {
    float: left;
    margin-right: 10px;
    width: 23%;
    height: auto; }
  #side-events .calendar-img h5 {
    display: block;
    background: url(../../images/event-icon.png) no-repeat top center;
    color: #000;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 30px;
    padding: 24px 0; }
  #side-events .event-item h4,
  #side-events .event-item h4 a,
  #side-events .event-item p {
    line-height: 17px;
    text-align: left;
    margin-bottom: 20px;
    color: #102C40;
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 15px; }

#id_payment_method {
  border: none;
  height: auto; }

.has-danger {
  color: red; }

.committees-wrap h4 {
  margin-top: 40px; }

.committees-wrap .committee-officers .table > thead > tr > th {
  color: black;
  background-color: transparent; }
