.mg-top-10 {
  margin-top: 10px;
}

.mg-top-20 {
  margin-top: 20px;
}

.mg-top-30 {
  margin-top: 30px;
}

.mg-top-40 {
  margin-top: 40px;
}

.mg-top-50 {
  margin-top: 50px;
}

.mg-bottom-10 {
  margin-bottom: 10px;
}

.mg-bottom-20 {
  margin-bottom: 20px;
}

.mg-bottom-30 {
  margin-bottom: 30px;
}

.mg-bottom-40 {
  margin-bottom: 40px;
}

.mg-bottom-50 {
  margin-bottom: 50px;
}

#side-events {
  margin: 15px 0;

  h2 {
    color: #3C6FA9;
    border-bottom: 1px solid #ececec;
    padding-bottom: 15px;
  }

  .event-txt {
    float: left;
    width: 73%;
    padding-top: 10px;
    margin-bottom: 15px;
  }

  .calendar-img {
    float: left;
    margin-right: 10px;
    width: 23%;
    height: auto;
  }

  .calendar-img h5 {
    display: block;
    background: url(../../images/event-icon.png) no-repeat top center;
    color: #000;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 30px;
    padding: 24px 0;
  }

  .event-item h4,
  .event-item h4 a,
  .event-item p {
    line-height: 17px;
    text-align: left;
    margin-bottom: 20px;
    color: #102C40;
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 15px;
  }

}

#id_payment_method {
  border: none;
  height: auto;
}

.has-danger {
  color: red;
}

.committees-wrap {
  h4{
    margin-top: 40px;
  }
  .committee-officers {

    .table > thead > tr > th {
      color: black;
      background-color: transparent;
    }
  }
}